import global from './global/selectors'
import auth from './auth/selectors'
import user from '../app/users/store/selectors'
import request from '../app/request/store/selectors'
import cros from '../app/cros/store/selectors'
import labels from '../app/labels/store/selectors'
import logs from '../app/logs/store/selectors'
import notifications from './notifications/selectors'
import notificationsBacklog from '../app/notificationsBacklog/store/selectors'

const rootSelectors = {
  global,
  auth,
  user,
  request,
  cros,
  labels,
  logs,
  notifications,
  notificationsBacklog
}

export default rootSelectors
