import { createAsyncAction } from 'helpers/common'
import { PagedResult } from 'models/base/api-response'
import {
  INotificationPagedRequest,
  INotificationPopulated
} from 'models/notifications/notification'

const getNotificationsBacklog = createAsyncAction<
  INotificationPagedRequest,
  PagedResult<INotificationPopulated>,
  undefined
>('[NOTIFICATIONS BACKLOG] Get Notifications')

const notificationsBacklogActions = {
  getNotificationsBacklog
}

export default notificationsBacklogActions
