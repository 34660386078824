import { HomeOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import BreadcrumbItem from 'components/BreadcrumbItem'
import useLocale from 'hooks/useLocale'
import { Fragment, ReactElement, useMemo } from 'react'
import { useLocation } from 'react-router'
import './styles.less'

const nonClickableSubroute: string[] = [
  'new',
  'edit',
  'labels',
  'createcertificate',
  'sign',
  'view',
  'preview'
]
const translateableRoutes: string[] = [
  'new',
  'edit',
  'labels',
  'createcertificate',
  'sign',
  'requests',
  'preview',
  'cros',
  'users',
  'home',
  'logs',
  'createcertificate',
  'notifications',
  'view',
  'reports'
]

interface IBreadcrumbProps {
  breadcrumbPath?: string | null
}

export default function Breadcrumb({ breadcrumbPath }: IBreadcrumbProps): ReactElement {
  const { pathname } = useLocation()
  const { t } = useLocale()

  const routes = useMemo(
    () => (breadcrumbPath ?? pathname).split('/').filter(x => x !== ''),
    [pathname, breadcrumbPath]
  )

  return (
    <Space direction="horizontal" size="small" data-testid="breadCrumb">
      <BreadcrumbItem
        key={`${routes.length}-breadcrumb-base`}
        icon={<HomeOutlined />}
        linkTo="/home"
      ></BreadcrumbItem>
      <div className="breadcrumb-subroute">/</div>
      {routes.map((subRoute, i) => (
        <Fragment key={`${i}-${subRoute}`}>
          <BreadcrumbItem
            text={translateableRoutes.includes(subRoute) ? t(`routelabels.${subRoute}`) : subRoute}
            linkTo={
              nonClickableSubroute.includes(subRoute) || i === routes.length - 1
                ? undefined
                : `/${routes.slice(0, i + 1).join('/')}`
            }
          ></BreadcrumbItem>
          {i !== routes.length - 1 && (
            <div key={`${i}-${subRoute}-breadcrumb-separator`} className="breadcrumb-subroute">
              /
            </div>
          )}
        </Fragment>
      ))}
    </Space>
  )
}
