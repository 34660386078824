import { createAction } from '@reduxjs/toolkit'
import { ILabelSize } from 'models/labels/label'
import { IRequest } from 'models/requests/request'
import { createAsyncAction } from '../../../helpers/common'

export interface IValidateLabel {
  labelSize: ILabelSize
  requestId: string
}

export interface ISetLabelsPrinted {
  body: { isPrinted: boolean }
  requestId: string
}

const toggleEditMode = createAction<boolean>('[LABELS] Toggle Edit Mode')

const validateLabels = createAsyncAction<IValidateLabel, IRequest, undefined>(
  '[LABELS] Verify Labels'
)

const setLabelsPrinted = createAsyncAction<ISetLabelsPrinted, IRequest, undefined>(
  '[LABELS] Set Labels Printed'
)

const labelsActions = {
  validateLabels,
  setLabelsPrinted,
  toggleEditMode
}

export default labelsActions
