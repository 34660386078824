import { createSelector } from 'reselect'
import { ICrosState } from './reducer'
import { REDUCERNAMES } from '../../../helpers/enum'
import { getReducer } from '../../../helpers/common'

const reducer = getReducer<ICrosState>(REDUCERNAMES.CROS)

const cros = createSelector(reducer, state => state.cros)
const loading = createSelector(reducer, state => state.loading)
const saving = createSelector(reducer, state => state.saving)
const selectedCro = createSelector(reducer, state => state.selectedCro)

const crosSelector = {
  cros,
  loading,
  saving,
  selectedCro
}

export default crosSelector
