import { createSelector } from 'reselect'
import { IAuthState } from './reducer'
import { getReducer } from '../../helpers/common'
import { REDUCERNAMES } from '../../helpers/enum'

const reducer = getReducer<IAuthState>(REDUCERNAMES.AUTH)

const user = createSelector(reducer, state => state.user)
const validating = createSelector(reducer, state => state.validating)
const userRole = createSelector(reducer, state => ({
  roleUuid: state.user?.role ?? null,
  roleName: state.user?.roleName?.toString() ?? null
}))

const authSelectors = {
  user,
  validating,
  userRole
}

export default authSelectors
