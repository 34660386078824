import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import rootActions from '../rootactions'
import { IUserInfo } from '../../models/auth'

export interface IAuthState {
  user: IUserInfo | null
  validating: boolean
}

const initState: IAuthState = {
  user: null,
  validating: false
}

export default createReducer(initState, builder => {
  builder
    .addCase(rootActions.authActions.validateUser.request, state => ({
      ...state,
      validating: true
    }))
    .addCase(rootActions.authActions.validateUser.success, (state, action) => ({
      ...state,
      user: action.payload
    }))
    .addCase(rootActions.authActions.updateUserRole, (state, action) => ({
      ...state,
      user: state.user
        ? {
            ...state.user,
            roleName: action.payload.roleName,
            role: action.payload.role
          }
        : null
    }))
    .addMatcher(
      isAnyOf(
        rootActions.authActions.validateUser.success,
        rootActions.authActions.validateUser.failure
      ),
      state => ({
        ...state,
        validating: false
      })
    )
    .addMatcher(
      isAnyOf(rootActions.authActions.validateUser.request, rootActions.authActions.logout),
      state => ({
        ...state,
        user: null
      })
    )
})
