import { createAction, Store } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { IAsyncAction } from '../models/common'
import { REDUCERNAMES } from './enum'

export const createAsyncAction = <A, B, C>(actionType: string): IAsyncAction<A, B, C> => ({
  request: createAction<A>(`${actionType} Request`),
  success: createAction<B>(`${actionType} Success`),
  failure: createAction<C>(`${actionType} Failure`)
})

export const getQueryFromUrl = (url: string): { [key: string]: string | number } | null => {
  const queryRegex = /[?&]/g
  if (url.match(queryRegex) === null) return null
  return Object.fromEntries(
    url
      .split(queryRegex)
      .slice(1)
      .map(value => {
        const equalLocation = value.indexOf('=')
        return [value.substr(0, equalLocation), value.substr(equalLocation + 1)]
      })
  )
}

export const getQueryFromUrlSearchParams = (
  query: URLSearchParams
): { [key: string]: string | number } | null => {
  const obj: { [key: string]: string | number } = {}
  query.forEach((v, k) => {
    obj[k] = v
  })
  return obj
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const implementsTKeys = <T>(obj: any, keys: (keyof T)[]): obj is T => {
  if (!obj || !Array.isArray(keys)) {
    return false
  }

  const implementKeys = keys.reduce((impl, key) => impl && key in obj, true)

  return implementKeys
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const implementsSomeTKeys = <T>(queryParams: any, keys: (keyof T)[]): queryParams is T => {
  if (!queryParams || !Array.isArray(keys)) {
    return false
  }
  let propertiesMatch = true
  const objectKeys = Object.keys(queryParams)
  objectKeys.forEach(property => {
    if (!keys.includes(property as keyof T)) {
      propertiesMatch = false
    }
  })

  return propertiesMatch
}

export const getReducer =
  <T = unknown>(reducerKey: REDUCERNAMES): ((state: Store) => T) =>
  state =>
    state[reducerKey]

export const cleanObj = (
  obj: {
    [key: string]: string | Array<string> | undefined | null | number
  },
  avoidProps: Array<string> = []
): { [key: string]: string | Array<string> } => {
  const newObj = { ...obj }

  Object.keys(obj).forEach(key => {
    const val = newObj[key]

    if (!val || (val && Array.isArray(val) && !val.length) || avoidProps.find(p => p === key))
      delete newObj[key]
  })

  return newObj as { [key: string]: string | Array<string> }
}

export const nullEmptyStrings = (values: { [key: string]: any }): { [key: string]: any } => {
  const cleanedValues = { ...values }
  Object.keys(values).forEach(key => {
    if (values[key] === '') cleanedValues[key] = null
  })
  return cleanedValues
}

export const removeUndefined = (obj: { [key: string]: any }): { [key: string]: any } => {
  const newObj = {}
  Object.keys(obj).forEach(key => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeUndefined(obj[key])
    else if (obj[key] !== undefined) newObj[key] = obj[key]
  })
  return newObj
}

export const encodeQueryData = <T>(
  data: {
    [key in keyof T]: string | string[] | number | undefined | null | boolean | Date
  }
): string => {
  const params: Array<string | null> = Object.entries(data)
    .map(([k, value]) => {
      // Return null if value is empty/null but 0 allows
      if (!value && value !== 0) return null

      if (typeof value === 'string') {
        return `${encodeURIComponent(k)}=${encodeURIComponent(value.trim())}`
      }
      if (typeof value === 'boolean' || typeof value === 'number') {
        return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`
      }
      if (Array.isArray(value)) {
        return `${encodeURIComponent(k)}=${value.map(x => encodeURIComponent(x)).join(',')}`
      }
      if (value instanceof Date && dayjs(value).isValid()) {
        return `${encodeURIComponent(k)}=${dayjs(value).toISOString()}`
      }
      return null
    })
    .filter(x => x)

  return params.length ? params.join('&') : ''
}

export const downloadFile = (name: string, location: string): void => {
  const link = document.createElement('a')
  link.href = location
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
