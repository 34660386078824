import { LogoutOutlined, NotificationOutlined } from '@ant-design/icons'
import { Badge, Button, Popover, Row, Select, Space, Tooltip } from 'antd'
import logo from 'assets/images/logo.svg'
import ManagerNotification from 'components/NotificationsManager'
import { LANGS } from 'helpers/enum'
import useAuthentification from 'hooks/useAuthentification'
import useLocale from 'hooks/useLocale'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import rootActions from 'store/rootactions'
import rootSelectors from 'store/rootselectors'
import './styles.less'

const { Option } = Select

export default function Header(): ReactElement {
  const auth = useAuthentification()

  const { t, setLang } = useLocale()
  const d = useDispatch()

  const totalUnread: number = useSelector(rootSelectors.notifications.totalUnread)
  const visible: boolean = useSelector(rootSelectors.notifications.visible)

  const [notificationWrapperHeight, setNotificationWrapperHeight] = useState<number>(
    window.innerHeight - 150
  )

  const resizeWindow = () => setNotificationWrapperHeight(window.innerHeight - 150)

  useEffect(() => {
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const selectedLang = useSelector(rootSelectors.global.language)

  const handleLogout = () => {
    d(rootActions.notificationActions.clearReducer())
    auth.logout()
  }

  const handleLangChange = (lang: LANGS) => {
    setLang(lang)
  }

  return (
    <div id="header-wrapper">
      <img src={logo} alt="ISDIN logo" id="header-logo" />
      <Row align="middle" justify="end" style={{ marginBottom: '10px' }}>
        <Space size="middle">
          {selectedLang && (
            <Select onChange={handleLangChange} value={selectedLang} data-testid="searchInput">
              {Object.keys(LANGS).map(lang => (
                <Option
                  value={LANGS[lang]}
                  key={`header-lang-select-option-${LANGS[lang]}`}
                  disabled={LANGS[lang] === selectedLang}
                >
                  {t(`languages.${LANGS[lang]}`)}
                </Option>
              ))}
            </Select>
          )}
          <Badge count={totalUnread}>
            <Popover
              content={
                <div style={{ width: '600px', height: `${notificationWrapperHeight}px` }}>
                  {visible && <ManagerNotification />}
                </div>
              }
              trigger="click"
              placement="bottomRight"
              className="no-padding"
              visible={visible}
              onVisibleChange={() => d(rootActions.notificationActions.setVisiblePopOver(!visible))}
            >
              <Tooltip title={t('common.notifications')}>
                <Button
                  shape="circle"
                  icon={<NotificationOutlined />}
                  onClick={() => d(rootActions.notificationActions.setVisiblePopOver(!visible))}
                ></Button>
              </Tooltip>
            </Popover>
          </Badge>
          <Tooltip title={t('common.logout')}>
            <Button shape="circle" icon={<LogoutOutlined />} onClick={handleLogout}></Button>
          </Tooltip>
        </Space>
      </Row>
    </div>
  )
}
