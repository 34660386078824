import { AxiosResponse } from 'axios'
import { PagedResult } from 'models/base/api-response'
import { INotificationPopulated } from 'models/notifications/notification'
import { IPagedNotificationsRequest } from 'store/notifications/actions'
import { instance } from './index.service'

export interface INotificationPopulatedResponse {
  notifications: PagedResult<INotificationPopulated>
  totalUnread: number
}

export interface INotificationToggleResponse {
  success: boolean
}

const notificationService = {
  getAll: async (
    pagedRequest: IPagedNotificationsRequest
  ): Promise<AxiosResponse<INotificationPopulatedResponse>> =>
    instance.get(
      `/notification/recent?limit=${pagedRequest.pageSize}&page=${pagedRequest.currentPage}&onlyUnread=${pagedRequest.onlyUnread}`
    ),
  changeState: async (id: string): Promise<AxiosResponse<INotificationToggleResponse>> =>
    instance.put(`notification/toggleRead/${id}`),
  markAllAsRead: async (): Promise<AxiosResponse<{ success: boolean }>> =>
    instance.put(`notification/markAllAsRead`),
  getNotificationsBacklog: async (
    encodedQuery: string
  ): Promise<AxiosResponse<PagedResult<INotificationPopulated>>> =>
    instance.get(`/notification/getAll?${encodedQuery}`)
}

export default notificationService
