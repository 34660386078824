import { createAction } from '@reduxjs/toolkit'
import { IUserInfo } from 'models/auth'
import { createAsyncAction } from 'helpers/common'
import { IAuthTokens } from 'models/common'

const validateUser = createAsyncAction<IAuthTokens, IUserInfo, undefined>('[AUTH] Validate User')

const logout = createAction('[AUTH] Logout')

const updateUserRole = createAction<{ roleName: string; role: string }>('[AUTH] Update user role')

const authActions = {
  validateUser,
  logout,
  updateUserRole
}

export default authActions
