import { IRoleEntry } from '../../models/auth'

const ROLES: { [key: string]: IRoleEntry } = {
  ADMIN: {
    es_name: 'Administrador',
    en_name: 'Administrator',
    code: 'a09b2d9a-384d-4a3d-ac70-3499121cad4a'
  },
  SOLICITANTE: {
    es_name: 'Solicitante',
    en_name: 'Requester',
    code: 'a5455038-c0de-40e5-8dde-3a589a7477ef'
  },
  MANAGER: {
    es_name: 'Mánager',
    en_name: 'Manager',
    code: 'e6e56329-0f52-45fb-80fe-a4c90da27237'
  },
  TECNICO: {
    es_name: 'Técnico',
    en_name: 'Technician',
    code: 'a17dd985-dd43-4b82-9a4b-5ca7a57d7d5c'
  },
  DIRECCION_TECNICA: {
    es_name: 'Dirección técnica',
    en_name: 'Technical direction',
    code: 'fe88cae5-ce8a-4171-8733-bb7597adbb2d'
  },
  GERENTE_DE_ENVIO: {
    es_name: 'Gestor de envíos',
    en_name: 'Handler',
    code: 'c518e6ea-6615-49a1-8d42-1d139af212f3'
  },
  ESPECTADOR: {
    es_name: 'Observador',
    en_name: 'Viewer',
    code: '5b95dbea-3679-4677-ad5e-b5d1a88008b3'
  }
}

export type RoleKeys = keyof typeof ROLES

export default ROLES
