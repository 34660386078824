import { AxiosResponse } from 'axios'
import { encodeQueryData } from 'helpers/common'
import { IUser, IUsersPagedRequest } from 'models/users/user'
import { IResponseWithPayload } from '../models/base/api-response'
import { IMessageResponse, IPaginatedQuery } from '../models/common'

import { instance } from './index.service'

const userService = {
  get: async (request: IUsersPagedRequest): Promise<IPaginatedQuery<IUser> | { items: IUser[] }> =>
    instance.get(`/user?${encodeQueryData(request)}`),
  getBusinessArea: async (): Promise<IPaginatedQuery<string[]>> => instance.get('/user/areas'),
  getAll: async (): Promise<{ items: IUser[] }> => instance.get('user'),
  changeRole: async (
    user: IUser,
    newRole: string
  ): Promise<AxiosResponse<IResponseWithPayload<IUser>>> =>
    instance.put(`/user/${user._id}/role`, { role: newRole }),
  assignManager: async (
    userId: string,
    managerId: string
  ): Promise<AxiosResponse<IMessageResponse<IUser>>> =>
    instance.put(`/user/${userId}/manager`, { manager: managerId }),
  deleteUser: async (user: IUser): Promise<AxiosResponse<any>> =>
    instance.delete(`/user/${user._id}`),
  updatePreferences: async (input: {
    lang: string
  }): Promise<AxiosResponse<IResponseWithPayload<IUser>>> =>
    instance.put(`/user/preferences`, input),
  getUserInfo: async (): Promise<{ data: IUser }> => instance.get('user/info')
}

export default userService
