import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import authSelectors from 'store/auth/selectors'
import useLocale from 'hooks/useLocale'
import { IRouteLabel, IRoute } from 'models/routes'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import './styles.less'

const { SubMenu, Item } = Menu

export interface ISiderProps {
  labels: IRouteLabel[]
  isExpanded?: boolean
  setIsExpanded?: (isCollapsed: boolean) => unknown
  appRoutes?: IRoute[]
}

export default function Sider({
  labels,
  isExpanded: isExpandedProp,
  setIsExpanded: setIsExpandedProp,
  appRoutes
}: ISiderProps): ReactElement {
  const { t } = useLocale()
  const { pathname } = useLocation()
  const breakpoints = useBreakpoint()

  const { roleName } = useSelector(authSelectors.userRole)

  const [openKey, setOpenKey] = useState<string | null>()
  const [internalIsExpanded, setInternalIsExpanded] = useState<boolean>(true)

  const isExpanded = useMemo(
    () => isExpandedProp ?? internalIsExpanded,
    [isExpandedProp, internalIsExpanded]
  )

  const shouldMenuBeCollapsed = useMemo(() => {
    const isMD = breakpoints.md === undefined ? false : breakpoints.md
    const isSM = breakpoints.sm === undefined ? false : breakpoints.sm
    const isXS = breakpoints.xs === undefined ? true : breakpoints.xs

    return (isXS || isSM) && !isMD
  }, [breakpoints])

  const isUserAllowed = useCallback(
    (label: IRouteLabel): boolean => {
      if (!appRoutes) return true

      const route = appRoutes.find(x => x.path === label.path)

      if (!route || !route.allowedRoles) return true
      if (!roleName) return false

      return route.allowedRoles.includes(roleName)
    },
    [roleName, appRoutes]
  )

  const isLabelShown = useCallback(
    (label: IRouteLabel): boolean => {
      if (!(label.children && label.children.length)) return true

      return label.children.some(x => isUserAllowed(x))
    },
    [roleName, appRoutes, isUserAllowed]
  )

  useEffect(() => {
    if (setIsExpandedProp) setIsExpandedProp(!shouldMenuBeCollapsed)
    else setInternalIsExpanded(!shouldMenuBeCollapsed)
  }, [shouldMenuBeCollapsed])

  useEffect(() => {
    if (!isExpanded) {
      setOpenKey(null)
      return
    }

    const label = labels.find(mainLabel =>
      mainLabel.children?.find(childLabel => childLabel.path === pathname)
    )

    if (label) setOpenKey(`sider-submenu-${label.path}`)
  }, [pathname, isExpanded])

  const toggleSider = () => {
    if (setIsExpandedProp) setIsExpandedProp(!isExpanded)
    else setInternalIsExpanded(!isExpanded)
  }

  const handleSubmenuClick = (path: string): void => {
    if (isExpanded)
      setOpenKey(openKey && `sider-submenu-${path}` === openKey ? null : `sider-submenu-${path}`)
  }

  return (
    <div>
      <Menu
        mode={isExpanded ? 'inline' : 'vertical'}
        inlineCollapsed={!isExpanded}
        className="side-menu"
        selectedKeys={[pathname]}
        openKeys={openKey ? [openKey] : undefined}
      >
        {labels
          .filter(label => (label.children ? isLabelShown(label) : isUserAllowed(label)))
          .map(lab =>
            lab.children ? (
              <SubMenu
                key={`sider-submenu-${lab.path}`}
                icon={lab.Icon && <lab.Icon />}
                title={t(`routelabels.${lab.label}`)}
                onTitleClick={() => handleSubmenuClick(lab.path)}
              >
                {lab.children.filter(isUserAllowed).map(child => (
                  <Item icon={child.Icon && <child.Icon />} key={child.path}>
                    <Link to={child.path}>{t(`routelabels.${child.label}`)}</Link>
                  </Item>
                ))}
              </SubMenu>
            ) : (
              <Item icon={lab.Icon && <lab.Icon />} key={lab.path}>
                <Link to={lab.path}>{t(`routelabels.${lab.label}`)}</Link>
              </Item>
            )
          )}
        <Item
          id="sider-collapse-btn"
          icon={isExpanded ? <LeftOutlined /> : <RightOutlined />}
          onClick={toggleSider}
          key="toggle-sider-button"
        />
      </Menu>
    </div>
  )
}
