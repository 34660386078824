import { ReactElement, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Result } from 'antd'
import { IRoute } from 'models/routes'
import { Switch } from 'react-router'
import { IAsyncReducer } from 'models/common'
import store from 'store'
import useLocale from 'hooks/useLocale'
import PrivateRoute from '../PrivateRoute'
import { IAsyncSaga } from '../../models/common'

interface IModule {
  routes: IRoute[]
  reducer?: IAsyncReducer | IAsyncReducer[]
  saga?: IAsyncSaga
}

export default function Module({ routes, reducer, saga }: IModule): ReactElement {
  const { t } = useLocale()

  useEffect(() => {
    if (reducer)
      if (Array.isArray(reducer)) store.replaceReducers(...reducer)
      else store.replaceReducers(reducer)

    if (saga) store.injectSaga(saga.name, saga.saga)
  }, [reducer, saga])

  return (
    <Switch>
      {routes.map(route => (
        <PrivateRoute {...route} key={`private-route-${route.path}`} />
      ))}
      <Route path="*">
        <Result status="404" title="404" subTitle={t('msg.privateRoute.notFound')} />
      </Route>
    </Switch>
  )
}
