import { all, call, put, takeLatest, takeLeading } from '@redux-saga/core/effects'
import { message } from 'antd'
import { REDUCERNAMES } from 'helpers/enum'
import translate from 'helpers/translate'
import { IAsyncSaga } from 'models/common'
import notificationService, {
  INotificationPopulatedResponse,
  INotificationToggleResponse
} from 'service/notification.service'
import rootActions from 'store/rootactions'

function* getNotifications(
  action: ReturnType<typeof rootActions.notificationActions.getNotifications.request>
) {
  try {
    const data: INotificationPopulatedResponse = yield call(
      notificationService.getAll,
      action.payload
    )
    yield put(rootActions.notificationActions.getNotifications.success(data))
  } catch (err) {
    yield put(rootActions.notificationActions.getNotifications.failure)
    message.error(translate('msg.error.getNotifications'))
  }
}

function* changeNotificationState(
  action: ReturnType<typeof rootActions.notificationActions.changeNotificationState.request>
) {
  if (action.payload.notification._id)
    try {
      const data: INotificationToggleResponse = yield call(
        notificationService.changeState,
        action.payload.notification._id
      )
      if (data.success) {
        const responseNotification = { ...action.payload.notification }
        responseNotification.isRead = action.payload.isRead
        yield put(
          rootActions.notificationActions.changeNotificationState.success(responseNotification)
        )
        yield put(rootActions.notificationActions.changeTotalUnread(responseNotification.isRead))
      }
    } catch (err) {
      yield put(rootActions.notificationActions.changeNotificationState.failure)
      message.error(translate('msg.error.getNotifications'))
    }
}

function* markAllNotificationsAsRead() {
  try {
    yield call(notificationService.markAllAsRead)
    yield put(rootActions.notificationActions.markAllAsRead.success())
  } catch (err) {
    yield put(rootActions.notificationActions.markAllAsRead.failure)
    message.error(translate('msg.error.markAllAsRead'))
  }
}

function* allSagas(): Generator {
  yield all([
    takeLeading(rootActions.notificationActions.getNotifications.request, getNotifications),
    takeLatest(
      rootActions.notificationActions.changeNotificationState.request,
      changeNotificationState
    ),
    takeLatest(rootActions.notificationActions.markAllAsRead.request, markAllNotificationsAsRead)
  ])
}

const notificationSagas: IAsyncSaga = {
  name: REDUCERNAMES.NOTIFICATIONS,
  saga: allSagas
}

export default notificationSagas
