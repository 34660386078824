import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import { PagedResult } from 'models/base/api-response'
import { INotificationPopulated } from 'models/notifications/notification'
import rootActions from 'store/rootactions'

export const SHOW_ONLY_UNREAD_KEY = 'SHOW_ONLY_UNREAD_KEY'

export interface INotificationPopulatedsState {
  notifications: PagedResult<INotificationPopulated>
  loading: boolean
  totalUnread: number
  visible: boolean
  showOnlyUnread: boolean
}

const initState: INotificationPopulatedsState = {
  notifications: {
    items: [],
    total: 0
  },
  loading: false,
  totalUnread: 0,
  visible: false,
  showOnlyUnread: localStorage.getItem(SHOW_ONLY_UNREAD_KEY) === 'true'
}

function updateTotalUnread(
  state: INotificationPopulatedsState,
  { payload: isReadFlag }: ReturnType<typeof rootActions.notificationActions.changeTotalUnread>
) {
  let updatedTotal = state.totalUnread
  /* El usuari la marca com a llegida (isRead = true) */
  if (isReadFlag) updatedTotal -= 1
  /* El usuari la marca com a NO llegida (isRead = false) */
  if (!isReadFlag) updatedTotal += 1
  return {
    ...state,
    totalUnread: updatedTotal
  }
}

// function arrayUnique(array) {
//   const a = array.concat()
//   for (let i = 0; i < a.length; i += 1) {
//     for (let j = i + 1; j < a.length; j += 1) {
//       if (a[i] === a[j]) a.splice((j -= 1), 1)
//     }
//   }

//   return a
// }

export default createReducer(initState, builder => {
  builder
    .addCase(rootActions.notificationActions.getNotifications.request, state => ({
      ...state,
      loading: true
    }))
    .addCase(rootActions.notificationActions.toggleOnlyUnread, state => ({
      ...state,
      showOnlyUnread: !state.showOnlyUnread
    }))
    .addCase(rootActions.notificationActions.setVisiblePopOver, (state, action) => ({
      ...state,
      visible: action.payload
    }))
    .addCase(rootActions.notificationActions.changeTotalUnread, updateTotalUnread)
    .addCase(rootActions.notificationActions.getNotifications.success, (state, action) => ({
      ...state,
      notifications: action.payload.notifications,
      totalUnread: action.payload.totalUnread
    }))
    .addCase(rootActions.notificationActions.markAllAsRead.success, state => ({
      ...state,
      notifications: {
        items: state.notifications.items.map(n => ({
          ...n,
          isRead: true
        })),
        total: state.notifications.total
      },
      totalUnread: 0
    }))
    .addCase(rootActions.notificationActions.changeNotificationState.success, (state, action) => ({
      ...state,
      notifications: {
        ...state.notifications,
        items: state.notifications.items.map(notification =>
          notification._id === action.payload._id ? action.payload : notification
        )
      }
    }))
    .addCase(rootActions.notificationActions.clearNotifications, state => ({
      ...state,
      notifications: {
        items: [],
        total: 0
      }
    }))
    .addCase(rootActions.notificationActions.clearReducer, () => initState)
    .addMatcher(
      isAnyOf(
        rootActions.notificationActions.getNotifications.success,
        rootActions.notificationActions.getNotifications.failure
      ),
      state => ({
        ...state,
        loading: false
      })
    )
})
