import en from '../assets/locale/en.json'
import es from '../assets/locale/es.json'
import { LANGS, KEYS } from './enum'

const langs = {
  en,
  es
}

const getNextValue = (ob: typeof en | typeof es, ids: string[]): string =>
  ids.length === 1 ? ob[ids[0]] : getNextValue(ob[ids[0]], ids.slice(1))

export default function translate(
  id: string,
  values?: Record<string, string>,
  transform?: 'upper' | 'lower' | 'capital',
  locale?: LANGS
): string {
  try {
    const ids = id.trim().split('.')

    let currentLang: any // TODO: posar "typeof en" per verificar que les propietats de es.json es troben a en.json

    if (locale) {
      currentLang = langs[locale]
    } else {
      const savedLocale = (localStorage.getItem(KEYS.LOCALE) as LANGS) ?? LANGS.EN
      currentLang = langs[savedLocale]
    }

    let value: string = getNextValue(currentLang, ids)

    if (values) {
      const keys = Object.keys(values)
      keys.forEach(key => {
        value = value.replace(`{{${key}}}`, values[key])
      })
    }

    if (transform)
      switch (transform) {
        case 'upper':
          value = value.toUpperCase()
          break
        case 'lower':
          value = value.toLowerCase()
          break
        case 'capital':
          value = value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()
      }

    return value !== undefined && value !== '' ? value : id
  } catch (e) {
    return id
  }
}
