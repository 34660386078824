import { createSelector } from 'reselect'
import { getReducer } from '../../../helpers/common'
import { REDUCERNAMES } from '../../../helpers/enum'
import { ILabelsState } from './reducer'

const reducer = getReducer<ILabelsState>(REDUCERNAMES.LABELS)

const loading = createSelector(reducer, state => state.loading)
const editMode = createSelector(reducer, state => state.editMode)
const saving = createSelector(reducer, state => state.saving)

const labelsSelectors = {
  loading,
  saving,
  editMode
}

export default labelsSelectors
