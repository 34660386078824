import { Button, Col, notification, Row, Typography } from 'antd'
import ROLES from 'assets/constants/roles'
import { getQueryFromUrl } from 'helpers/common'
import translate from 'helpers/translate'
import useNotifications from 'hooks/useNotifications'
import { INotificationPayload } from 'models/common'
import { INotificationPopulated } from 'models/notifications/notification'
import { IUser } from 'models/users/user'
import React, { ReactChild, ReactChildren, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useHistory } from 'react-router'
import { SocketContext } from 'service/index.service'
import rootActions from 'store/rootactions'
import rootSelectors from 'store/rootselectors'

interface AuxProps {
  children: ReactChild | ReactChildren
}

const { Title } = Typography

const getRoleKeyByCode = (code: string) => {
  const role = Object.keys(ROLES).find(k => ROLES[k].code === code)
  return role
}

const SoketIoContainer = ({ children }: AuxProps): JSX.Element => {
  const socketContext = useContext(SocketContext)
  const h = useHistory()
  const d = useDispatch()
  const { refreshNotifications } = useNotifications()
  const currUser = useSelector(rootSelectors.auth.user)
  const lang = useSelector(rootSelectors.global.language)

  useEffect(() => {
    if (socketContext && socketContext.disconnected) socketContext.connect()
    return () => {
      socketContext.disconnect()
    }
  }, [])

  const refreshRequests = () => {
    const isRequestPathActive = !!matchPath(window.location.pathname, '/requests')
    if (!isRequestPathActive) return
    const params = getQueryFromUrl(window.location.search)
    if (params) {
      const pagedRequest = { ...params }
      d(
        rootActions.requestActions.getRequests.request({
          ...pagedRequest,
          paginate: true
        })
      )
    } else {
      d(
        rootActions.requestActions.getRequests.request({
          currentPage: 0,
          pageSize: 10,
          paginate: true
        })
      )
    }
  }

  useEffect(() => {
    let notificationSubscription: any = null
    let newNotificationSubscription: any = null
    let changeRoleNotificationSubscription: any = null
    if (!changeRoleNotificationSubscription)
      changeRoleNotificationSubscription = socketContext.on(
        'change-role-notification',
        (user: IUser) => {
          if (user._id === currUser?.id) {
            const roleName = getRoleKeyByCode(user.role)
            if (roleName) d(rootActions.authActions.updateUserRole({ roleName, role: user.role }))
          }
        }
      )
    if (!notificationSubscription)
      notificationSubscription = socketContext.on(
        'notification',
        ({ users, msg }: INotificationPayload) => {
          console.log('NEW REQUEST SIGNED!')
          console.log('USER: ', currUser)
          console.log('USERS: ', users)
          if ((!users && currUser) || (users && currUser && users.indexOf(currUser.id) !== -1))
            notification.open({
              message: (
                <Col style={{ padding: '15px' }}>
                  <Row>
                    <Title level={5}>
                      {translate(
                        'notifications.msg.newDocumentSignedAlert',
                        undefined,
                        undefined,
                        lang
                      )}
                    </Title>
                  </Row>
                </Col>
              ),
              placement: 'bottomRight',
              duration: 0
            })
          if (msg.type === 'signed_certificate' && msg.args[1])
            d(rootActions.requestActions.changeStatusToSignById(msg.args[1]))
        }
      )
    if (!newNotificationSubscription)
      newNotificationSubscription = socketContext.on(
        'new-notification',
        (res: INotificationPopulated) => {
          refreshRequests()
          if (
            currUser &&
            (res.usersNotify as Array<IUser>).find(user => user._id === currUser.id)
          ) {
            refreshNotifications()
            notification.open({
              message: (
                <Col style={{ padding: '15px' }}>
                  <Row>
                    <Title level={5}>
                      {res.action === 'SIGN_CERTIFICATE'
                        ? translate(
                            'notifications.msg.newDocumentSignedAlert',
                            undefined,
                            undefined,
                            lang
                          )
                        : translate(
                            'notifications.msg.newNotificationAlert',
                            undefined,
                            undefined,
                            lang
                          )}
                    </Title>
                  </Row>
                  <Row style={{ marginTop: '10px' }}>
                    {res.action === 'SIGN_CERTIFICATE' ? (
                      <Button onClick={() => h.push(`/requests/preview/${res.request._id}`)}>
                        {translate('requests.labels.requestDetails', undefined, undefined, lang)}
                      </Button>
                    ) : (
                      <Button onClick={() => h.push(`/notifications`)}>
                        {translate(
                          'notifications.labels.seeNotifications',
                          undefined,
                          undefined,
                          lang
                        )}
                      </Button>
                    )}
                  </Row>
                </Col>
              ),
              placement: 'bottomRight',
              duration: 3000
            })
          }
        }
      )
    return () => {
      notificationSubscription.off()
      newNotificationSubscription.off()
      changeRoleNotificationSubscription.off()
    }
  }, [socketContext, currUser, lang])

  return <>{children}</>
}

export default SoketIoContainer
