export const BUSINESS_UNIT = {
  SPF: 'SPF',
  FTP: 'FTP',
  DERC: 'DERC',
  DERX: 'DERX',
  AE: 'AE',
  OC: 'OC'
}

export const REQUEST_STATUS = {
  PENDING_ASIGNAMENT: 'PENDING_ASIGNAMENT',
  PAUSED: 'PAUSED',
  CANCELED: 'CANCELED',
  REJECTED: 'REJECTED',
  ASIGNED: 'ASIGNED',
  LABELS_VERIFIED: 'LABELS_VERIFIED',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  WAITING_SIGNATURE: 'WAITING_SIGNATURE',
  SIGNED: 'SIGNED',
  FINISHED: 'FINISHED',
  COMPLETED: 'COMPLETED'
}

export const REQUEST_COLUMNS = {
  REQUEST_CODE: 'REQUEST_CODE',
  CRO: 'CRO',
  TECHNICAL_ASSIGNED: 'TECHNICAL_ASSIGNED',
  REQUESTER: 'REQUESTER',
  PRODUCT_REFERENCE: 'PRODUCT_REFERENCE',
  CREATION_DATE: 'CREATION_DATE',
  STATUS: 'STATUS',
  TECHNICAL_DIRECTION: 'MANAGER',
  MANAGER: 'MANAGER'
}

export const TEST_CATEGORY = {
  FOTO_TEST_IN_VIVO: 'FOTO TEST IN VIVO',
  FOTO_TEST_IN_VITRO: 'FOTO TEST IN VITRO',
  OTHERS: 'OTHERS'
}

export const SIGNATURE_STATUS = {
  in_queue: 'in_queue',
  ready: 'ready',
  signing: 'signing',
  completed: 'completed',
  expired: 'expired',
  canceled: 'canceled',
  declined: 'declined',
  error: 'error'
}

export const TEST_TYPE = [
  'SPF',
  'SPF Wet Skin',
  'WR',
  'VWR',
  'Rubproof',
  'Sweat Resistant',
  'PPD',
  'UVA',
  'CW',
  'Broad Spectrum',
  'FOTOESTABILIDAD',
  'SPF IN VITRO',
  'BLUE LIGHT',
  'IR',
  'PT',
  'HRIPT',
  'Phototest',
  'Non comedogenicity',
  'Hydration kinetics',
  'User test',
  'Anti-pollution carbon particles',
  'oil-control',
  'open test',
  'consumer test',
  'efficacy study',
  'outdoor test',
  '*other'
] as const

export const PAO_TYPES = ['1M', '2M', '3M', '6M', '12M'] as const

export const SAMPLE_LABEL_FIELDS = {
  requestCode: 'requestCode',
  productName: 'productName',
  referenceCode: 'referenceCode',
  company: 'company',
  sampleUnits: 'sampleUnits',
  sampleAmount: 'sampleAmount',
  testType: 'testType',
  testCategory: 'testCategory',
  deadLine: 'deadLine',
  info: 'info',
  infoLabel: 'infoLabel',
  PAO: 'PAO',
  batch: 'batch',
  productDescription: 'productDescription',
  labelSize: 'labelSize',
  anonymizedName: 'anonymizedName',
  productRefConfirmation: 'productRefConfirmation',
  specificationDescription: 'specificationDescription',
  expirationDate: 'expirationDate'
}

export const SAMPLE_LABEL_CONSTANTS = {
  NEVER_COVER_THIS_LABEL: 'NEVER COVER THIS LABEL',
  SUNCARE_PRODUCT: 'SUNCARE PRODUCT',
  CHECK_HOMOGENEITY: 'CHECK HOMOGENEITY OF THE SAMPLE BEFORE STARTING TEST',
  SHAKE_IT: 'SHAKE IT BEFORE USE',
  DO_NOT_OPEN: 'DO NOT OPEN UNTIL RECEIVING INSTRUCTIONS FROM ISDIN'
}

export const LOG_ACTIONS = {
  CREATE: 'CREATE',
  CREATE_SIGNATURE: 'CREATE_SIGNATURE',
  CREATE_LABELS: 'CREATE_LABELS',
  UPDATE: 'UPDATE',
  UPDATE_ROLE: 'UPDATE_ROLE',
  UPDATE_SIGNATURE: 'UPDATE_SIGNATURE',
  DELETE: 'DELETE',
  REJECT: 'REJECT',
  ASSIGN: 'ASSIGN',
  ASSIGN_TECHNICIAN: 'ASSIGN_TECHNICIAN',
  PRINT: 'PRINT'
}

export const ENTITIES = {
  USER: 'USER',
  CRO: 'CRO',
  REQUEST: 'REQUEST',
  SIGNATURE: 'SIGNATURE'
}

export const NOTIFICATION_ACTIONS = {
  REJECT: 'REJECT',
  ASSIGN: 'ASSIGN',
  ASSIGN_ME: 'ASSIGN_ME',
  CREATE: 'CREATE',
  VALIDATE_LABELS: 'REJECTED',
  CREATE_CERTIFICATE: 'CREATE_CERTIFICATE',
  SIGN_CERTIFICATE: 'SIGN_CERTIFICATE'
  /* COMPLETE ?  */
}

export const LENGUAGES = {
  ES: 'ES',
  ENG: 'ENG'
}

export type TestCategory = 'FOTO TEST IN VIVO' | 'FOTO TEST IN VITRO' | 'OTHERS'

export type SignatureStatus = keyof typeof SIGNATURE_STATUS
export type RequestStatus = keyof typeof REQUEST_STATUS
export type BusinessUnit = keyof typeof BUSINESS_UNIT
export type TestTypes = typeof TEST_TYPE[number]
export type PaoTypes = typeof PAO_TYPES[number]
export type SamplePrintFields = keyof typeof SAMPLE_LABEL_FIELDS
export type SamplePrintConstants = keyof typeof SAMPLE_LABEL_CONSTANTS
export type INotificationActions = keyof typeof NOTIFICATION_ACTIONS
export type LenguageType = keyof typeof LENGUAGES
export type Entities = keyof typeof ENTITIES
export type LogActions = keyof typeof LOG_ACTIONS
export type RequestCoulmns = keyof typeof REQUEST_COLUMNS
