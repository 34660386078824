import { all, call, put, takeLatest } from '@redux-saga/core/effects'
import { message } from 'antd'
import { REDUCERNAMES } from 'helpers/enum'
import translate from 'helpers/translate'
import { IAsyncSaga } from 'models/common'
import requestService from 'service/request.service'
import rootActions from 'store/rootactions'

function* validateLabel(
  action: ReturnType<typeof rootActions.labelsActions.validateLabels.request>
) {
  try {
    const res = yield call(requestService.validateLabels, action.payload)
    yield put(rootActions.requestActions.updateStatusLabelsVerified(res.data))
    yield put(rootActions.labelsActions.validateLabels.success(res.data))
    message.success(translate('msg.success.validate_labels_success'))
  } catch (err) {
    yield put(rootActions.labelsActions.validateLabels.failure())
    message.error(translate('msg.error.verify_labels_failure'))
  }
}

function* setLabelsPrinted(
  action: ReturnType<typeof rootActions.labelsActions.setLabelsPrinted.request>
) {
  try {
    const res = yield call(requestService.setLabelsPrinted, action.payload)
    yield put(rootActions.labelsActions.setLabelsPrinted.success(res.data))
    yield put(rootActions.requestActions.setLabelsPrinted(true))
  } catch (err) {
    yield put(rootActions.labelsActions.setLabelsPrinted.failure())
  }
}

function* allSagas(): Generator {
  yield all([takeLatest(rootActions.labelsActions.validateLabels.request, validateLabel)])
  yield all([takeLatest(rootActions.labelsActions.setLabelsPrinted.request, setLabelsPrinted)])
}

const labelSagas: IAsyncSaga = {
  name: REDUCERNAMES.LABELS,
  saga: allSagas
}

export default labelSagas
