import ROLES from './roles'

/* ANY = PERMITE EN ENTIDADES CREADAS POR OTROS */
/* MY = PERMITE SOLO EN ENTIDADES CREADAS POR EL MISMO USUARIO */
/* FORCE = PERMITE EN ENTIDADES CREADAS POR OTROS Y INDEPENDIENTEMENTE DEL ESTADO */

const PERMISSIONS: { [key: string]: Array<keyof typeof ROLES> } = {
  CHANGEUSERROLE: ['ADMIN'],
  ASSIGN_MANAGER: ['ADMIN', 'MANAGER'],
  ASSIGN_REQUEST: ['ADMIN', 'MANAGER', 'TECNICO'],
  CRO_CREATE_EDIT_DELETE: ['ADMIN', 'SOLICITANTE'],
  CREATE_LABEL_ANY_REQUEST: ['ADMIN'],
  CREATE_LABEL_MY_REQUEST: ['ADMIN', 'TECNICO'],
  VIEW_LABEL: ['ADMIN', 'TECNICO', 'GERENTE_DE_ENVIO'],
  EDIT_LABEL_INFORMATION_ANY_REQUEST: ['ADMIN'],
  EDIT_LABEL_INFORMATION_MY_REQUEST: ['ADMIN', 'TECNICO'],
  EDIT_LABEL_SIZE: ['ADMIN', 'GERENTE_DE_ENVIO'],
  PRINT_LABEL: ['ADMIN', 'TECNICO', 'GERENTE_DE_ENVIO'],
  DOWNLOAD_CERTIFICATE: ['ADMIN', 'GERENTE_DE_ENVIO'],
  REJECT_ANY_REQUEST: ['MANAGER'],
  REJECT_PENDING_SIGNATURE: ['DIRECCION_TECNICA'],
  REJECT_MY_REQUEST: ['TECNICO'],
  DELETE_ANY_REQUEST: ['ADMIN'],
  DELETE_MY_REQUEST: ['ADMIN', 'SOLICITANTE'],
  FORCE_DELETE_REQUEST: ['ADMIN'],
  EDIT_ANY_REQUEST: ['ADMIN'],
  EDIT_MY_REQUEST: ['ADMIN', 'SOLICITANTE', 'TECNICO'],
  SEE_REQUEST_FILES: [
    'ADMIN',
    'SOLICITANTE',
    'TECNICO',
    'GERENTE_DE_ENVIO',
    'DIRECCION_TECNICA',
    'MANAGER',
    'ESPECTADOR'
  ],
  DELETE_UPLOAD_ANY_REQUEST_FILES: ['ADMIN', 'GERENTE_DE_ENVIO', 'MANAGER'],
  DELETE_UPLOAD_MY_REQUEST_FILES: ['ADMIN', 'TECNICO', 'SOLICITANTE'],
  DELETE_USER: ['ADMIN'],
  SHOW_REQUESTER_FILTER: [
    'ADMIN',
    'SOLICITANTE',
    'TECNICO',
    'GERENTE_DE_ENVIO',
    'DIRECCION_TECNICA',
    'MANAGER',
    'ESPECTADOR'
  ],
  SHOW_TECHNICIAN_FILTER: [
    'ADMIN',
    'SOLICITANTE',
    'TECNICO',
    'GERENTE_DE_ENVIO',
    'DIRECCION_TECNICA',
    'MANAGER',
    'ESPECTADOR'
  ],
  SHOW_MANAGER_FILTER: [
    'ADMIN',
    'SOLICITANTE',
    'TECNICO',
    'GERENTE_DE_ENVIO',
    'DIRECCION_TECNICA',
    'MANAGER',
    'ESPECTADOR'
  ],
  SIGN_REQUEST: ['ADMIN', 'DIRECCION_TECNICA'],
  CREATE_CERTIFICATE: ['ADMIN', 'TECNICO', 'GERENTE_DE_ENVIO'],
  COMPLETE_SPF_REQUEST: ['ADMIN', 'SOLICITANTE'],
  COMPLETE_NO_SPF_REQUEST: ['ADMIN', 'SOLICITANTE', 'TECNICO']
}

export type Permissions = keyof typeof PERMISSIONS

export default PERMISSIONS
