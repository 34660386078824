import { Spin } from 'antd'
import FakeLogin from 'components/FakeLogin'
import Login from 'components/Login'
import Overlay from 'components/Overlay'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import rootSelectors from 'store/rootselectors'
import App from './app'
import './assets/styles/main.less'
import useAuthentification from './hooks/useAuthentification'
import store from './store'

function MainApp() {
  const { isLoggedIn } = useAuthentification()
  const validating = useSelector(rootSelectors.auth.validating)

  if (validating)
    return (
      <Overlay centered>
        <Spin></Spin>
      </Overlay>
    )

  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'staging' ||
    process.env.REACT_APP_ENV === 'local'
  ) {
    return isLoggedIn ? <App /> : <FakeLogin />
  }
  return isLoggedIn ? <App /> : <Login />
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MainApp />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
