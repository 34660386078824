import { message } from 'antd'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getQueryFromUrl } from '../helpers/common'
import rootActions from '../store/rootactions'
import rootSelectors from '../store/rootselectors'
import useLocale from './useLocale'
import authService from '../service/auth.service'
import { IAuthTokens } from '../models/common'

interface IUseAuthentification {
  isLoggedIn: boolean
  login: (token: IAuthTokens) => void
  logout: () => void
}

export default function useAuthentification(): IUseAuthentification {
  const d = useDispatch()
  const { t } = useLocale()
  const location = useLocation()

  const savedTokens = authService.getTokens()

  const user = useSelector(rootSelectors.auth.user)
  const validating = useSelector(rootSelectors.auth.validating)

  const isLoggedIn = useMemo(() => user !== null, [user])

  const login = (tokens: IAuthTokens) => {
    d(rootActions.authActions.validateUser.request(tokens))
  }

  useEffect(() => {
    if (!validating && !user) {
      const queryParams = getQueryFromUrl(location.search)

      const idToken = queryParams?.idToken?.toString()
      const accessToken = queryParams?.accessToken?.toString()

      if ((idToken && accessToken) || savedTokens) {
        const token: IAuthTokens | null =
          idToken && accessToken ? { accessToken, idToken } : null ?? savedTokens

        if (token) {
          login(token)
        } else {
          message.warning(t('msg.info.noToken'), 5000)
        }
      }
    }
  }, [savedTokens, location, validating, user])

  const logout = () => {
    authService.logout()
  }

  return { isLoggedIn, login, logout }
}
