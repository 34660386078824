import PERMISSIONS from 'assets/constants/permissions'
import ROLES from 'assets/constants/roles'
import { IRoleEntry } from 'models/auth'
import { allRoutes } from '../app/routes'
import { KEYS, LANGS } from './enum'

export const findRoleEntry = (uuid: string): IRoleEntry | null =>
  Object.values(ROLES).find(x => x.code === uuid) ?? null

export const findRoleEntryName = (uuid: string): keyof typeof ROLES | null =>
  (Object.keys(ROLES).find(x => uuid === ROLES[x].code) as keyof typeof ROLES)?.toString() ?? null

export const getRoleName = (uuid: string, lang?: LANGS): string | null => {
  const currLocale: LANGS = lang ?? (localStorage.getItem(KEYS.LOCALE) as LANGS) ?? LANGS.EN

  const roleEntry = findRoleEntry(uuid)

  if (!roleEntry) return null

  return roleEntry[`${currLocale}_name`]
}

export const checkPermissions = (
  userRoleName: keyof typeof ROLES | null | undefined,
  permissions: keyof typeof PERMISSIONS | Array<keyof typeof ROLES>
): boolean => {
  if (!permissions || !userRoleName) return false

  const allowedRoles = !Array.isArray(permissions) ? PERMISSIONS[permissions] : permissions

  return allowedRoles.includes(userRoleName)
}

export const checkRoutePermission = (
  userRoleName: keyof typeof ROLES | null | undefined,
  routePath: string
): boolean => {
  const foundRoute = allRoutes.find(x => x.path === routePath)

  if (!foundRoute || !userRoleName) return false

  if (!foundRoute.allowedRoles) return true

  return foundRoute.allowedRoles.includes(userRoleName)
}
