import { createAction } from '@reduxjs/toolkit'
import { RcFile } from 'antd/lib/upload'
import { createAsyncAction } from 'helpers/common'
import { PagedResult } from 'models/base/api-response'
import { IRedirect } from 'models/common'
import { BusinessUnit, RequestStatus } from 'models/dictionaries'
import { ICreateRequest, IRequest, IRequestPagedRequest } from 'models/requests/request'
import { ISignature } from 'models/signatures/signature'
import { IUser, IUsersPagedRequest } from 'models/users/user'

export interface IAssignTechnicianPayload {
  id: string
  userAssignmentId: string
}

export interface IRequestFilterData {
  assignedTechnicians: IUser[]

  activeRequesters: IUser[]
}

export interface IRequestRejectPayload {
  id: string
  reason: string
}

export interface ICompleteRequestPayload {
  requestId: string
  comment?: string
  attachment: RcFile
}

export interface IDeleteFileRequestPayload {
  requestCode: string
  fileName: string
}

export interface ICompleteNoSPFPayload {
  requestId: string
}

const getRequests = createAsyncAction<IRequestPagedRequest, PagedResult<IRequest>, undefined>(
  '[REQUESTS] Get Requests List'
)

const getReports = createAsyncAction<IRequestPagedRequest, PagedResult<IRequest>, undefined>(
  '[REQUESTS] Get Requests Reports'
)

const getRequest = createAsyncAction<string, IRequest, undefined>('[REQUESTS] Get Request')

const getAppTechnicians = createAsyncAction<IUsersPagedRequest, IUser[], undefined>(
  '[REQUESTS] Get App Technicians'
)

const createRequest = createAsyncAction<
  { input: ICreateRequest; attachments: RcFile[] | null; history: any },
  IRequest,
  undefined
>('[REQUESTS] Create request')

const deleteRequest = createAsyncAction<string, string, undefined>('[REQUESTS] Delete request')

const getUrlSignature = createAsyncAction<{ id: string; history: any }, undefined, undefined>(
  '[REQUESTS] Get url signature'
)

const setSignatureWaiting = createAsyncAction<
  { id: string; signature: ISignature },
  IRequest,
  undefined
>('[REQUESTS] Set signature waiting')

const updateRequest = createAsyncAction<
  {
    input: { [key: string]: any }
    status: RequestStatus
    bussinesUnit?: BusinessUnit
    id: string
    history: any
  },
  IRequest,
  undefined
>('[REQUESTS] Update request')

const uploadFileRequest = createAsyncAction<
  { attachments: RcFile[]; requestCode: string },
  IRequest,
  undefined
>('[REQUESTS] Upload Request file')

const uploadFile = createAsyncAction<
  { input: { [key: string]: any }; attachment: File; id: string },
  IRequest,
  undefined
>('[REQUESTS] Upload file')

const deleteFileRequest = createAsyncAction<IDeleteFileRequestPayload, IRequest, undefined>(
  '[REQUESTS] Delete Request File'
)

const completeRequestUpload = createAsyncAction<ICompleteRequestPayload, IRequest, undefined>(
  '[REQUESTS] Complete Request Upload'
)
const completeRequestNoSPF = createAsyncAction<ICompleteNoSPFPayload, IRequest, undefined>(
  '[REQUESTS] Complete Request No SPF'
)

const assignTechnician = createAsyncAction<IAssignTechnicianPayload, IRequest, undefined>(
  '[REQUESTS] Assign Technician'
)

const showAssignTechnicianModal = createAction<boolean>('[REQUESTS] Show Assign Technician Modal')

const showCustomizeColumnsModal = createAction<boolean>('[REQUESTS] Show Customize Columns Modal')

const updateStatusLabelsVerified = createAction<IRequest>(
  '[REQUESTS] Update Status Labels Verified'
)

const rejectRequest = createAsyncAction<{ payload: IRequestRejectPayload }, IRequest, undefined>(
  '[REQUESTS] Reject Request'
)

const pauseRequest = createAsyncAction<
  { input: { [key: string]: any }; history: any },
  IRequest,
  undefined
>('[REQUESTS] Paused Request')

const showCancelRequestModal = createAction<boolean>('[REQUESTS] Show Cancel Request Modal')

const showUploadFileModal = createAction<boolean>('[REQUESTS] Show Upload File Modal')

const showCompleteRequestModal = createAction<boolean>(
  '[REQUESTS] Show Complete Request File Modal'
)

const getRequestByCode = createAsyncAction<string, IRequest | null, undefined>(
  '[REQUESTS] Get request by code'
)

const clearSelection = createAction<undefined>('[REQUESTS] Clear selection')

const setSelectedRequest = createAction<IRequest>('[REQUESTS] Set Selected Request')

const clearReducer = createAction('[REQUESTS] Clear Reducer')

const changeStatusToSignById = createAction<string>('[REQUESTS] Change status to sign by id')

const createCertificate = createAsyncAction<
  { certificate: string; requestId: string; history: any },
  { url: string; requestId: string },
  undefined
>('[REQUESTS] Create certificate')

const fetchFilterData = createAsyncAction<undefined, IRequestFilterData, undefined>(
  '[REQUESTS] Fetch Filter Data'
)

const getManagers = createAsyncAction<undefined, Array<IUser>, undefined>('[REQUESTS] Get managers')

const downloadSignedCertificate = createAsyncAction<
  { requestId: string; requestCode: string },
  undefined,
  undefined
>('[REQUESTS] Download Certificate')

const assignTechnicalDirection = createAsyncAction<IRedirect<string>, IRequest, undefined>(
  '[REQUESTS] Assign technical Direction'
)

const setLabelsPrinted = createAction<boolean>('[REQUESTS] Set Labels printed')

const requestActions = {
  getRequests,
  getAppTechnicians,
  assignTechnician,
  showAssignTechnicianModal,
  rejectRequest,
  pauseRequest,
  showCancelRequestModal,
  getRequestByCode,
  createRequest,
  updateRequest,
  deleteRequest,
  getRequest,
  clearSelection,
  clearReducer,
  showUploadFileModal,
  fetchFilterData,
  uploadFile,
  deleteFileRequest,
  uploadFileRequest,
  completeRequestUpload,
  completeRequestNoSPF,
  getUrlSignature,
  setSignatureWaiting,
  createCertificate,
  changeStatusToSignById,
  downloadSignedCertificate,
  getReports,
  assignTechnicalDirection,
  showCompleteRequestModal,
  showCustomizeColumnsModal,
  setSelectedRequest,
  getManagers,
  updateStatusLabelsVerified,
  setLabelsPrinted
}

export default requestActions
