import { lazy } from 'react'
import { IRoute } from '../../models/routes'

const crosList = lazy(() => import('./pages/CrosList'))
const createCro = lazy(() => import('./pages/CroManagement'))
const previewCro = lazy(() => import('./pages/CroPreview'))

const routes: IRoute[] = [
  {
    component: crosList,
    path: '/cros/',
    privateRoute: true,
    exact: true,
    allowedRoles: ['ADMIN', 'SOLICITANTE', 'ESPECTADOR']
  },
  {
    component: createCro,
    path: '/cros/new',
    privateRoute: true,
    exact: true,
    allowedRoles: ['ADMIN', 'SOLICITANTE']
  },
  {
    component: createCro,
    path: '/cros/edit/:id',
    privateRoute: true,
    exact: true,
    allowedRoles: ['ADMIN', 'SOLICITANTE']
  },
  {
    component: previewCro,
    path: '/cros/preview/:id',
    privateRoute: true,
    exact: true
  }
]

export default routes
