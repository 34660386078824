import { Button, Typography } from 'antd'
import ROLES from 'assets/constants/roles'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import tokens from '../../assets/constants/testTokens'
import Logo from '../../assets/images/logo.svg'
import { getRoleName } from '../../helpers/roles'
import './styles.less'

const { Title } = Typography

export default function FakeLogin(): ReactElement {
  return (
    <div id="wrapper" data-testid="loginPage">
      <div id="formContent">
        <img src={Logo} alt="ISDIN-LOGO" id="topLogo" />
        <Title level={2}>SIMULATE LOGIN BY ROLES</Title>
        <div id="buttonsWrapper">
          {tokens.map(({ roleName, accessToken, idToken }) => (
            <Button key={`login-component-role-access-${roleName}`} style={{ margin: '0.3rem' }}>
              <Link to={`/?accessToken=${accessToken}&idToken=${idToken}`}>
                {getRoleName(ROLES[roleName].code)}
              </Link>
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}
