import crosActions from 'app/cros/store/actions'
import requestActions from '../app/request/store/actions'
import userActions from '../app/users/store/actions'
import authActions from './auth/actions'
import labelsActions from '../app/labels/store/actions'
import globalActions from './global/actions'
import logsActions from '../app/logs/store/actions'
import notificationActions from './notifications/actions'
import notificationsBacklogActions from '../app/notificationsBacklog/store/actions'

const rootActions = {
  globalActions,
  authActions,
  userActions,
  crosActions,
  requestActions,
  labelsActions,
  logsActions,
  notificationActions,
  notificationsBacklogActions
}

export default rootActions
