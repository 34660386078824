import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import rootActions from 'store/rootactions'

export interface ILabelsState {
  loading: boolean
  saving: boolean
  editMode: boolean
}

const initState: ILabelsState = {
  loading: false,
  saving: false,
  editMode: false
}

export default createReducer(initState, builder => {
  builder
    .addCase(rootActions.labelsActions.validateLabels.request, state => ({
      ...state,
      saving: true
    }))
    .addCase(rootActions.labelsActions.validateLabels.failure, state => ({
      ...state,
      saving: false
    }))
    .addCase(rootActions.labelsActions.validateLabels.success, state => ({
      ...state,
      saving: false
    }))
    .addCase(rootActions.labelsActions.setLabelsPrinted.request, state => ({
      ...state,
      loading: true
    }))
    .addCase(rootActions.labelsActions.setLabelsPrinted.failure, state => ({
      ...state,
      loading: false
    }))
    .addCase(rootActions.labelsActions.toggleEditMode, (state, action) => ({
      ...state,
      editMode: action.payload
    }))
    .addMatcher(isAnyOf(rootActions.labelsActions.setLabelsPrinted.success), state => ({
      ...state,
      loading: false
    }))
})
