import { createAction } from '@reduxjs/toolkit'
import { createAsyncAction } from 'helpers/common'
import { IUser } from 'models/users/user'
import { LANGS, THEMES } from '../../helpers/enum'

const setLanguage = createAsyncAction<{ lang: LANGS }, undefined, undefined>(
  '[GLOBAL] Set Language'
)

const setTheme = createAction<THEMES>('[GLOBAL] Set Theme')

const redirect = createAction<string>('[GLOBAL] Redirect')

const setCustomBreadcrumb = createAction<string | null>('[GLOBAL] Set Custom Breadcrumb')

const getUserInfo = createAsyncAction<undefined, IUser, undefined>('[GLOBAL] Get user info')

const globalActions = {
  setLanguage,
  setTheme,
  redirect,
  setCustomBreadcrumb,
  getUserInfo
}

export default globalActions
