import { getReducer } from 'helpers/common'
import { REDUCERNAMES } from 'helpers/enum'
import { createSelector } from 'reselect'
import { INotificationPopulatedsState } from './reducer'

const reducer = getReducer<INotificationPopulatedsState>(REDUCERNAMES.NOTIFICATIONS)

const notifications = createSelector(reducer, state => {
  if (state.showOnlyUnread) {
    const notificationFiltered = state.notifications.items.filter(n => !n.isRead)
    return {
      total: notificationFiltered.length,
      items: notificationFiltered
    }
  }
  return state.notifications
})
const loading = createSelector(reducer, state => state.loading)
const totalUnread = createSelector(reducer, state => state.totalUnread)
const visible = createSelector(reducer, state => state.visible)
const showOnlyUnread = createSelector(reducer, state => state.showOnlyUnread)

const notificationSelectors = {
  notifications,
  loading,
  totalUnread,
  visible,
  showOnlyUnread
}

export default notificationSelectors
