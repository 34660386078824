import { createReducer } from '@reduxjs/toolkit'
import { LANGS, THEMES, KEYS } from '../../helpers/enum'
import rootActions from '../rootactions'

export interface IGlobalState {
  language: LANGS
  theme: THEMES
  redirectTo: string | null
  customBreadcrumb: string | null
}

const initState: IGlobalState = {
  language: LANGS.EN,
  theme: (localStorage.getItem(KEYS.THEME) as THEMES) ?? THEMES.LIGHT,
  redirectTo: null,
  customBreadcrumb: null
}

function handleLangChange(
  state: IGlobalState,
  action: ReturnType<typeof rootActions.globalActions.setLanguage.request>
): IGlobalState {
  localStorage.setItem(KEYS.LOCALE, action.payload.lang)

  return {
    ...state,
    language: action.payload.lang
  }
}

export default createReducer(initState, builder => {
  builder
    .addCase(rootActions.globalActions.setLanguage.request, handleLangChange)
    .addCase(rootActions.globalActions.setTheme, (state, action) => ({
      ...state,
      theme: action.payload
    }))
    .addCase(rootActions.globalActions.getUserInfo.success, (state, action) => ({
      ...state,
      language: action.payload.preferences?.lang === 'ES' ? LANGS.ES : LANGS.EN
    }))
    .addCase(rootActions.globalActions.redirect, (state, action) => ({
      ...state,
      redirectTo: action.payload
    }))
    .addCase(rootActions.globalActions.setCustomBreadcrumb, (state, action) => ({
      ...state,
      customBreadcrumb: action.payload
    }))
})
