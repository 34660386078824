import React, { Fragment, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import './styles.less'

export interface IBreadcrumbItemProps {
  text?: string
  icon?: React.ReactNode
  linkTo?: string
}

const BreadcrumbItem = ({ text, icon, linkTo }: IBreadcrumbItemProps): ReactElement => (
  <>
    {linkTo ? (
      <Link to={linkTo} className="breadcrumb-subroute link no-select">
        {icon ?? text}
      </Link>
    ) : (
      <div className="breadcrumb-subroute">{text}</div>
    )}
  </>
)

export default BreadcrumbItem
