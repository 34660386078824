import { createSelector } from 'reselect'
import { IGlobalState } from './reducer'
import { REDUCERNAMES } from '../../helpers/enum'
import { getReducer } from '../../helpers/common'

const reducer = getReducer<IGlobalState>(REDUCERNAMES.GLOBAL)

const language = createSelector(reducer, state => state.language)
const theme = createSelector(reducer, state => state.theme)
const redirectTo = createSelector(reducer, state => state.redirectTo)
const customBreadcrumb = createSelector(reducer, state => state.customBreadcrumb)

const globalSelectors = {
  language,
  theme,
  redirectTo,
  customBreadcrumb
}

export default globalSelectors
