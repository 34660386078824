import { message } from 'antd'
import { LANGS } from 'helpers/enum'
import decodeJwt from 'jwt-decode'
import { IUser } from 'models/users/user'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import authService from 'service/auth.service'
import userService from 'service/user.service'
import { findRoleEntryName } from '../../helpers/roles'
import translate from '../../helpers/translate'
import { IAccessTokenPayload, IIdTokenPayload, IUserInfo } from '../../models/auth'
import rootActions from '../rootactions'

function* validateToken(action: ReturnType<typeof rootActions.authActions.validateUser.request>) {
  try {
    const accessToken = decodeJwt(action.payload.accessToken) as IAccessTokenPayload

    const idToken = decodeJwt(action.payload.idToken) as IIdTokenPayload

    authService.setTokens(action.payload)

    const { data }: { data: IUser } = yield call(userService.getUserInfo)

    const roleEntryName = findRoleEntryName(data.role)

    if (!roleEntryName) throw Error('msg.error.unknownRole')

    const userInfo: IUserInfo = {
      id: idToken.id,
      email: accessToken.email,
      name: data.name,
      surname: data.surname,
      role: data.role,
      roleName: roleEntryName,
      lang: data.preferences.lang === 'ENG' ? LANGS.EN : LANGS.ES
    }

    yield put(rootActions.authActions.validateUser.success(userInfo))
  } catch (err) {
    authService.logout()
    yield put(rootActions.authActions.validateUser.failure())
    message.error(translate('msg.error.badToken'))
  }
}

export default function* authSagas(): Generator {
  yield all([takeLatest(rootActions.authActions.validateUser.request, validateToken)])
}
