import { createAction } from '@reduxjs/toolkit'
import { createAsyncAction } from 'helpers/common'
import { IPaginatedQuery } from 'models/common'
import { IPagedRequest } from 'models/base/api-request'
import { IUser } from '../../../models/users/user'

const getUsers = createAsyncAction<IPagedRequest, IPaginatedQuery<IUser>, undefined>(
  '[USERS] Get Users'
)

const changeUserRole = createAsyncAction<{ user: IUser; newRole: string }, IUser, undefined>(
  '[USERS] Change User Role'
)

const clearReducer = createAction<undefined>('[USERS] Clear Reducer')

const assignManagerToTechnician = createAsyncAction<
  { userId: string; managerId: string },
  IUser,
  undefined
>('[USERS] Assign Manager To Technician')

const deleteUser = createAsyncAction<IUser, IUser, undefined>('[USERS] Delete User')

const populateFilterData = createAsyncAction<
  undefined,
  { businessArea: string[]; managers: IUser[] },
  undefined
>('[USERS] Populate Filter Data')

const userActions = {
  getUsers,
  changeUserRole,
  clearReducer,
  assignManagerToTechnician,
  deleteUser,
  populateFilterData
}

export default userActions
