import {
  HistoryOutlined,
  HomeOutlined,
  IdcardOutlined,
  NodeCollapseOutlined,
  NotificationOutlined,
  TeamOutlined
} from '@ant-design/icons'
import { lazy } from 'react'
import { IRoute, IRouteLabel } from 'models/routes'
import crosRoutes from './cros/routes'
import userRoutes from './users/routes'
import requestRoutes from './request/routes'

// TODO: optimització webpack: https://webpack.js.org/api/module-methods/#magic-comments && https://manukyan.dev/posts/2019-04-15-code-splitting-for-redux-and-optional-redux-saga/

const home = lazy(() => import('./home/pages/home'))
const users = lazy(() => import('./users'))
const request = lazy(() => import('./request'))
const cros = lazy(() => import('./cros'))
const testground = lazy(() => import('./testground'))
const logs = lazy(() => import('./logs'))
const notificationsBacklog = lazy(() => import('./notificationsBacklog'))

export const routes: IRoute[] = [
  {
    path: '/home',
    component: home,
    exact: true,
    privateRoute: true,
    allowedRoles: [
      'ADMIN',
      'SOLICITANTE',
      'MANAGER',
      'TECNICO',
      'DIRECCION_TECNICA',
      'GERENTE_DE_ENVIO',
      'ESPECTADOR'
    ]
  },
  {
    path: '/notifications',
    component: notificationsBacklog,
    exact: true,
    privateRoute: true,
    allowedRoles: [
      'ADMIN',
      'SOLICITANTE',
      'MANAGER',
      'TECNICO',
      'DIRECCION_TECNICA',
      'GERENTE_DE_ENVIO',
      'ESPECTADOR'
    ]
  },
  {
    path: '/users',
    component: users,
    exact: false,
    privateRoute: true,
    allowedRoles: ['ADMIN', 'MANAGER', 'ESPECTADOR']
  },
  {
    path: '/cros',
    component: cros,
    exact: false,
    privateRoute: true,
    allowedRoles: ['ADMIN', 'SOLICITANTE', 'ESPECTADOR']
  },
  {
    path: '/requests',
    component: request,
    exact: false,
    privateRoute: true,
    allowedRoles: [
      'ADMIN',
      'SOLICITANTE',
      'MANAGER',
      'TECNICO',
      'DIRECCION_TECNICA',
      'GERENTE_DE_ENVIO',
      'ESPECTADOR'
    ]
  },
  {
    path: '/testground',
    component: testground,
    exact: true,
    privateRoute: false
  },
  {
    path: '/logs',
    component: logs,
    exact: false,
    privateRoute: true,
    allowedRoles: ['ADMIN']
  }
]

export const routeLabels: IRouteLabel[] = [
  {
    path: '/home',
    label: 'home',
    Icon: HomeOutlined
  },
  {
    path: '/notifications',
    label: 'notifications',
    Icon: NotificationOutlined
  },
  {
    path: '/users',
    label: 'userlist',
    Icon: TeamOutlined
  },
  {
    path: '/',
    label: 'cros',
    Icon: IdcardOutlined,
    children: [
      {
        path: '/cros',
        label: 'viewCro'
      },
      {
        path: '/cros/new',
        label: 'newCro'
      }
    ]
  },
  {
    path: '/requests',
    label: 'requests',
    Icon: NodeCollapseOutlined,
    children: [
      {
        path: '/requests',
        label: 'viewRequests'
      },
      {
        path: '/requests/new',
        label: 'newRequest'
      },
      {
        path: '/requests/view/reports',
        label: 'reports'
      }
    ]
  },
  {
    path: '/logs',
    label: 'logs',
    Icon: HistoryOutlined
  }
]

export const allRoutes: IRoute[] = [...routes, ...crosRoutes, ...userRoutes, ...requestRoutes]
