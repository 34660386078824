import { createSelector } from 'reselect'
import { IRequestState } from './reducer'
import { REDUCERNAMES } from '../../../helpers/enum'
import { getReducer } from '../../../helpers/common'

const reducer = getReducer<IRequestState>(REDUCERNAMES.REQUESTS)

const requests = createSelector(reducer, state => state.requests)
const reports = createSelector(reducer, state => state.reports)
const loading = createSelector(reducer, state => state.loading)
const isLoadingFilterData = createSelector(reducer, state => state.isLoadingFilterData)
const uploading = createSelector(reducer, state => state.uploadingFile)
const loadingRequestByCode = createSelector(reducer, state => state.loadingRequestByCode)
const showAssignModal = createSelector(reducer, state => state.showAssign)
const showCancelModal = createSelector(reducer, state => state.showCancelRequest)
const showUploadFile = createSelector(reducer, state => state.showUploadFile)
const showCompleteRequest = createSelector(reducer, state => state.showCompleteRequest)
const showCustomizeColumns = createSelector(reducer, state => state.showCustomizeColumns)
const requestSelected = createSelector(reducer, state => state.requestSelected)
const users = createSelector(reducer, state => ({
  activeRequesters: state.activeRequesters,
  assignedTechnicians: state.assignedTechnicians,
  technicians: state.technicians,
  managers: state.managers
}))
const submitting = createSelector(reducer, state => state.submitting)
const gettingUrl = createSelector(reducer, state => state.gettingUrl)
const isDownloading = createSelector(reducer, state => state.isDownloading)

const requestSelectors = {
  requests,
  loading,
  isLoadingFilterData,
  loadingRequestByCode,
  showAssignModal,
  showCancelModal,
  showCompleteRequest,
  showCustomizeColumns,
  requestSelected,
  showUploadFile,
  users,
  submitting,
  uploading,
  gettingUrl,
  isDownloading,
  reports
}

export default requestSelectors
