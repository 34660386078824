import { createSelector } from 'reselect'
import { IUsersState } from './reducer'
import { REDUCERNAMES } from '../../../helpers/enum'
import { getReducer } from '../../../helpers/common'

const reducer = getReducer<IUsersState>(REDUCERNAMES.USERS)

const users = createSelector(reducer, state => state.users)
const loading = createSelector(reducer, state => state.loading)
const total = createSelector(reducer, state => state.total)
const filterData = createSelector(reducer, state => ({
  managers: state.managers,
  businessArea: state.businessArea
}))
const customLoading = createSelector(reducer, state => state.customLoading)

const userSelectors = {
  users,
  loading,
  total,
  filterData,
  customLoading
}

export default userSelectors
