export enum LANGS {
  EN = 'en',
  ES = 'es'
}

export enum THEMES {
  DARK = 'dark',
  LIGHT = 'light'
}

export enum KEYS {
  LOCALE = 'locale',
  IDTOKEN = 'idToken',
  ACCESSTOKEN = 'accessToken',
  THEME = 'theme'
}

export enum REDUCERNAMES {
  GLOBAL = 'global',
  AUTH = 'auth',
  USERS = 'users',
  REQUESTS = 'requests',
  CROS = 'cros',
  LABELS = 'labels',
  LOGS = 'logs',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_BACKLOG = 'notificationsBacklog'
}
