import { all, call, put, takeLatest } from '@redux-saga/core/effects'
import { message } from 'antd'
import { KEYS, LANGS, REDUCERNAMES } from 'helpers/enum'
import translate from 'helpers/translate'
import { IAsyncSaga } from 'models/common'
import userService from 'service/user.service'
import rootActions from 'store/rootactions'

function* updateLanguage(action: ReturnType<typeof rootActions.globalActions.setLanguage.request>) {
  try {
    const input = {
      lang: action.payload.lang === 'en' ? 'ENG' : 'ES'
    }
    yield call(userService.updatePreferences, input)
    yield put(rootActions.globalActions.setLanguage.success())
    localStorage.setItem(KEYS.LOCALE, input.lang === 'ES' ? LANGS.ES : LANGS.EN)
  } catch (err) {
    yield put(rootActions.globalActions.setLanguage.failure)
    message.error(translate('msg.error.updateLanguage'))
  }
}

function* getUserInfo() {
  try {
    const { data } = yield call(userService.getUserInfo)
    localStorage.setItem(KEYS.LOCALE, data.preferences?.lang === 'ES' ? LANGS.ES : LANGS.EN)
    yield put(rootActions.globalActions.getUserInfo.success(data))
  } catch (err) {
    yield put(rootActions.globalActions.getUserInfo.failure)
    message.error(translate('msg.error.getUserInfo'))
  }
}

function* allSagas(): Generator {
  yield all([
    takeLatest(rootActions.globalActions.setLanguage.request, updateLanguage),
    takeLatest(rootActions.globalActions.getUserInfo.request, getUserInfo)
  ])
}

const globalSagas: IAsyncSaga = {
  name: REDUCERNAMES.GLOBAL,
  saga: allSagas
}

export default globalSagas
