import { combineReducers, Reducer } from '@reduxjs/toolkit'
import labelsReducer from 'app/labels/store/reducer'
import { REDUCERNAMES } from '../helpers/enum'
import { IAsyncReducer } from '../models/common'
import authReducer from './auth/reducer'
import notificationsReducer from './notifications/reducer'
import globalReducer from './global/reducer'

export const reducers = {
  [REDUCERNAMES.GLOBAL]: globalReducer,
  [REDUCERNAMES.AUTH]: authReducer,
  [REDUCERNAMES.LABELS]: labelsReducer,
  [REDUCERNAMES.NOTIFICATIONS]: notificationsReducer
}

export default function createRootReducer(...asyncReducers: IAsyncReducer[]): Reducer {
  if (asyncReducers && asyncReducers.length > 0) {
    asyncReducers.forEach(reducer => {
      if (!reducers[reducer.name]) reducers[reducer.name] = reducer.reducer
    })
  }

  return combineReducers(reducers)
}
