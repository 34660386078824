import { createSelector } from 'reselect'
import { REDUCERNAMES } from '../../../helpers/enum'
import { getReducer } from '../../../helpers/common'
import { ILogsState } from './reducer'

const reducer = getReducer<ILogsState>(REDUCERNAMES.LOGS)

const logs = createSelector(reducer, state => state.logs)
const loading = createSelector(reducer, state => state.loading)
const saving = createSelector(reducer, state => state.saving)
const selectedLog = createSelector(reducer, state => state.selectedLog)

const logsSelector = {
  logs,
  loading,
  saving,
  selectedLog
}

export default logsSelector
