import React, { ReactElement, useEffect, useRef, useState } from 'react'
import './styles.less'

type IOverlayProps = {
  centered?: boolean
  opacity?: number
  className?: string
  padding?: number
  children: JSX.Element
}

function Overlay({
  centered = false,
  opacity = 0.4,
  padding = 10,
  className,
  children
}: IOverlayProps): ReactElement {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [bgColor, setBgColor] = useState<string>('rgba(255,255,255,0.8)')

  useEffect(() => {
    if (className && wrapperRef) wrapperRef.current?.classList.add(className)
  }, [className])

  useEffect(() => {
    const rgba = `rgba(255,255,255,${opacity})`
    setBgColor(rgba)
  }, [opacity])

  return (
    <div
      ref={wrapperRef}
      className={`overlay-container ${centered && 'center'}`}
      style={{ padding: `${padding}px`, backgroundColor: bgColor }}
    >
      {children}
    </div>
  )
}

export default Overlay
