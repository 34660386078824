import { lazy } from 'react'
import { IRoute } from '../../models/routes'

const requests = lazy(() => import('./pages/RequestList'))
const requestManagement = lazy(() => import('./pages/RequestManagement'))
const previewRequest = lazy(() => import('./pages/RequestPreview'))
const labels = lazy(() => import('../labels'))
const createCertificate = lazy(() => import('./pages/CreateCertificate'))
const signRequest = lazy(() => import('./pages/RequestSign'))

const routes: IRoute[] = [
  {
    component: requests,
    path: '/requests/',
    privateRoute: true,
    exact: true,
    allowedRoles: [
      'ADMIN',
      'SOLICITANTE',
      'MANAGER',
      'TECNICO',
      'DIRECCION_TECNICA',
      'GERENTE_DE_ENVIO',
      'ESPECTADOR'
    ]
  },
  {
    component: requests,
    path: '/requests/view/:viewType',
    privateRoute: true,
    exact: true,
    allowedRoles: [
      'ADMIN',
      'SOLICITANTE',
      'MANAGER',
      'TECNICO',
      'DIRECCION_TECNICA',
      'GERENTE_DE_ENVIO',
      'ESPECTADOR'
    ]
  },
  {
    component: requestManagement,
    path: '/requests/new',
    privateRoute: true,
    exact: true,
    allowedRoles: ['ADMIN', 'SOLICITANTE']
  },
  {
    component: requestManagement,
    path: '/requests/edit/:id',
    privateRoute: true,
    exact: true,
    allowedRoles: ['ADMIN', 'SOLICITANTE', 'TECNICO']
  },
  {
    component: previewRequest,
    path: '/requests/preview/:id',
    privateRoute: true,
    exact: true
  },
  {
    component: signRequest,
    path: '/requests/sign/:id',
    privateRoute: true,
    exact: true,
    allowedRoles: ['ADMIN', 'DIRECCION_TECNICA']
  },
  {
    component: labels,
    path: '/requests/labels/:id',
    privateRoute: true,
    exact: true,
    allowedRoles: ['ADMIN', 'TECNICO', 'GERENTE_DE_ENVIO']
  },
  {
    component: createCertificate,
    path: '/requests/createcertificate/:id',
    privateRoute: true,
    exact: true,
    allowedRoles: ['ADMIN', 'TECNICO', 'GERENTE_DE_ENVIO']
  }
]

export default routes
