import { createSelector } from 'reselect'
import { REDUCERNAMES } from '../../../helpers/enum'
import { getReducer } from '../../../helpers/common'
import { INotificationBacklogState } from './reducer'

const reducer = getReducer<INotificationBacklogState>(REDUCERNAMES.NOTIFICATIONS_BACKLOG)

const notifications = createSelector(reducer, state => state.notifications)
const loading = createSelector(reducer, state => state.loading)

const notificationsBacklogSelector = {
  notifications,
  loading
}

export default notificationsBacklogSelector
