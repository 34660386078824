import { Typography } from 'antd'
import React, { ReactElement } from 'react'
import Logo from '../../assets/images/logo.svg'
import './styles.less'

const { Title } = Typography

export default function Login(): ReactElement {
  return (
    <div id="wrapper" data-testid="loginPage">
      <div id="formContent">
        <img src={Logo} alt="ISDIN-LOGO" id="topLogo" />
        <Title level={2}>Please access with the button on your app</Title>
      </div>
    </div>
  )
}
