import { Button, Col, Empty, Row, Spin, Switch, Tabs, Typography } from 'antd'
import NotificationItem from 'components/NotificationItem'
import useLocale from 'hooks/useLocale'
import useNotifications from 'hooks/useNotifications'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import rootActions from 'store/rootactions'
import './styles.less'
import { FixedSizeList as List } from 'react-window'
import rootSelectors from 'store/rootselectors'
import { SHOW_ONLY_UNREAD_KEY } from 'store/notifications/reducer'

const { Title } = Typography

export default function ManagerNotification(): React.ReactElement {
  const { t } = useLocale()
  const d = useDispatch()
  const { notifications, loading, totalUnread } = useNotifications()

  const notificationPopOver = useRef<HTMLDivElement>(null)
  const showOnlyUnread = useSelector(rootSelectors.notifications.showOnlyUnread)

  const toggleOnlyUnread = (v: boolean) => {
    localStorage.setItem(SHOW_ONLY_UNREAD_KEY, v ? 'true' : 'false')
    d(rootActions.notificationActions.toggleOnlyUnread())
  }

  const changeIsReadProperty = (id: string, isRead: boolean) => {
    const changeNotification = notifications.items.find(item => item._id === id)
    if (changeNotification)
      d(
        rootActions.notificationActions.changeNotificationState.request({
          notification: changeNotification,
          isRead
        })
      )
  }

  const markAllAsRead = () => {
    d(rootActions.notificationActions.markAllAsRead.request())
  }

  return (
    <div
      ref={notificationPopOver}
      className="p-relative full width height"
      style={{ padding: '10px' }}
    >
      <Row justify="space-between" align="middle" style={{ height: '50px' }}>
        <Col span={14}>
          <Title level={4} style={{ marginBottom: 0 }}>
            {t('common.notifications')}
          </Title>
          {/* <Title level={5} style={{ margin: 0, color: 'gray', fontSize: '14px' }}>
            {t('common.lastThirtyDays')}
          </Title> */}
        </Col>
        <label className="show-only-unread-wrapper">
          <span>{t('common.showOnlyUnread')}</span>
          <Switch size="small" checked={showOnlyUnread} onChange={v => toggleOnlyUnread(v)} />
        </label>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1" size="small">
            <Tabs.TabPane tab={t('common.lastThirtyDays')} key="1">
              {totalUnread > 0 && (
                <Row className="p-relative">
                  <Button
                    className="btn-mark-all-asread"
                    type="link"
                    size="small"
                    onClick={markAllAsRead}
                  >
                    {t('common.markAllAsRead')}
                  </Button>
                </Row>
              )}
              {loading && notifications.total === 0 && (
                <Row align="middle" justify="center" style={{ height: '50px' }}>
                  <Spin />
                </Row>
              )}
              {notifications.total === 0 ? (
                <Row justify="center" align="middle" style={{ paddingTop: '50px' }}>
                  <Col>
                    <Empty
                      description={
                        <Row>
                          <Col>
                            <Title level={4}>{t('common.emptyNotificationsText')}</Title>
                            <span>{t('common.emptyNotificationsHint')}</span>
                          </Col>
                        </Row>
                      }
                    />
                  </Col>
                </Row>
              ) : (
                <div className="full width height">
                  <List
                    className="scrollable-content"
                    height={
                      notificationPopOver.current
                        ? notificationPopOver.current.clientHeight - 150
                        : 0
                    }
                    itemCount={notifications.total}
                    itemSize={110}
                    width={
                      notificationPopOver.current ? notificationPopOver.current.clientWidth - 20 : 0
                    }
                  >
                    {({ index, style }) =>
                      notifications.items[index] ? (
                        <div style={style}>
                          <NotificationItem
                            notification={notifications.items[index]}
                            onIsReadToggle={changeIsReadProperty}
                          />
                        </div>
                      ) : null
                    }
                  </List>
                </div>
              )}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  )
}
