import { message } from 'antd'
import { createContext } from 'react'
import axios from 'axios'
import { io, Socket } from 'socket.io-client'
import authService from './auth.service'

const BACKENDURL = process.env.REACT_APP_API_URL ?? 'http://localhost:8080'

export const instance = axios.create({
  baseURL: BACKENDURL,
  headers: { Accept: 'application/json' }
})

export const socket = io(BACKENDURL, { transports: ['websocket'] })

export const SocketContext = createContext<Socket>(socket)

instance.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response?.status === 401) {
      window.location.href = '/home'
      message.error(
        "You don't have permission to access the previous route. Contact your supervisor"
      )
      authService.logout()
    }
    return Promise.reject(error)
  }
)
