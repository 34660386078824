import { Result } from 'antd'
import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import authSelectors from 'store/auth/selectors'
import { IRoute } from 'models/routes'
import useAuthentification from 'hooks/useAuthentification'
import useLocale from 'hooks/useLocale'

export default function PrivateRoute({ allowedRoles, privateRoute, ...rest }: IRoute): JSX.Element {
  const { roleName } = useSelector(authSelectors.userRole)
  const { isLoggedIn } = useAuthentification()
  const { t } = useLocale()

  const allowed = useMemo(() => <Route {...rest} key="adminroute" />, [rest])

  const notAllowed = useCallback(
    (props?: { title?: string; subTitle?: string }) => (
      <Route path={rest.path} key="userroute">
        <div id="not-allowed-wrapper" data-testid="notAllowedPage">
          <Result
            status="403"
            title={t(`msg.privateRoute.${props?.title ?? 'accessDenied'}`)}
            subTitle={t(`msg.privateRoute.${props?.subTitle ?? 'noPermission'}`)}
          />
        </div>
      </Route>
    ),
    [rest.path, t]
  )

  if (!privateRoute) return allowed
  if (privateRoute && !isLoggedIn) return notAllowed({ subTitle: 'loginFirst' })

  if (allowedRoles) {
    if (!roleName) return notAllowed()

    const isUserAllowed = allowedRoles.includes(roleName)

    return isUserAllowed ? allowed : notAllowed()
  }

  return allowed
}
