import { createAction } from '@reduxjs/toolkit'
import { IPagedRequest } from 'models/base/api-request'
import { PagedResult } from 'models/base/api-response'
import { ILog } from 'models/logs/log'
import { createAsyncAction } from '../../../helpers/common'

const getLogs = createAsyncAction<IPagedRequest, PagedResult<ILog>, undefined>('[CROS] Get Logs')

const getLogById = createAsyncAction<string, ILog, undefined>('[CROS] Get CRO by ID')

const clearReducer = createAction<undefined>('[CROS] Clear Reducer')

const setSelectedLog = createAction<ILog | null>('[CROS] Set Selected CRO')

const clearSelectedLog = createAction('[CROS] Clear Selected CRO')

const logsActions = {
  getLogs,
  getLogById,
  clearReducer,
  setSelectedLog,
  clearSelectedLog
}

export default logsActions
