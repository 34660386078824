import { REQUEST_STATUS } from 'models/dictionaries'

const REQUEST_STATUS_COLORS = {
  [REQUEST_STATUS.CANCELED]: 'red',
  [REQUEST_STATUS.PAUSED]: 'orange',
  [REQUEST_STATUS.FINISHED]: 'green',
  [REQUEST_STATUS.ASIGNED]: 'blue',
  [REQUEST_STATUS.PENDING_ASIGNAMENT]: 'cyan',
  [REQUEST_STATUS.WAITING_SIGNATURE]: 'purple',
  [REQUEST_STATUS.PENDING_SIGNATURE]: 'geekblue',
  [REQUEST_STATUS.REJECTED]: 'red',
  [REQUEST_STATUS.LABELS_VERIFIED]: 'green',
  [REQUEST_STATUS.COMPLETED]: 'purple'
}

export default REQUEST_STATUS_COLORS
