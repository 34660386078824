import { Font } from '@react-pdf/renderer'
import { Spin } from 'antd'
import PoppinsBold from 'assets/fonts/Poppins/Poppins-Bold.ttf'
import PoppinsLight from 'assets/fonts/Poppins/Poppins-Light.ttf'
import PoppinsRegular from 'assets/fonts/Poppins/Poppins-Regular.ttf'
import Breadcrumb from 'components/Breadcrumb'
import Header from 'components/Header'
import Module from 'components/Module/index'
import Sider from 'components/Sider'
import SoketIoContainer from 'components/SoketIoContainer'
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router'
import { socket, SocketContext } from 'service/index.service'
import rootActions from 'store/rootactions'
import rootSelectors from 'store/rootselectors'
import { allRoutes, routeLabels, routes } from './routes'
import './styles.less'

Font.register({
  family: 'Poppins',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      src: PoppinsRegular
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      src: PoppinsBold
    },
    {
      fontStyle: 'normal',
      fontWeight: 'light',
      src: PoppinsLight
    }
  ]
})

export default function App(): ReactElement {
  const location = useLocation()
  const d = useDispatch()
  const lang = useSelector(rootSelectors.global.language)
  const currUser = useSelector(rootSelectors.auth.user)
  const breadcrumbPath = useSelector(rootSelectors.global.customBreadcrumb)

  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(true)

  useEffect(() => {
    document.title = location.pathname.split('/')[1]
      ? `ISDIN Samples | ${location.pathname.split('/')[1].toUpperCase()}`
      : 'ISDIN Samples'
  }, [location])

  useEffect(() => {
    const html = document.querySelector('html')

    if (html) html.lang = lang
  }, [lang])

  useEffect(() => {
    d(rootActions.globalActions.getUserInfo.request())
  }, [currUser])

  return (
    <SocketContext.Provider value={socket}>
      <SoketIoContainer>
        <div id="app" data-testid="mainPage">
          <header id="header">
            <Header />
          </header>
          <aside
            id="sider"
            className={
              isMenuExpanded
                ? 'sider-expanded custom-scrollbar'
                : 'sider-collapsed custom-scrollbar'
            }
          >
            <Sider
              labels={routeLabels}
              isExpanded={isMenuExpanded}
              setIsExpanded={setIsMenuExpanded}
              appRoutes={allRoutes}
            />
          </aside>
          <div id="content" className="custom-scrollbar">
            <div id="breadcrumb">
              <Breadcrumb breadcrumbPath={breadcrumbPath} />
            </div>
            <Suspense
              fallback={
                <div className="full screen flex center" data-testid="loadingSuspense">
                  <Spin size="large" />
                </div>
              }
            >
              <Switch>
                <Redirect from="/" to="/home" exact />
                <Redirect from="/index.html" to="/home" />
                <Module routes={routes} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </SoketIoContainer>
    </SocketContext.Provider>
  )
}
