import { createAction } from '@reduxjs/toolkit'
import { IPagedRequest } from 'models/base/api-request'
import { PagedResult } from 'models/base/api-response'
import { ICRO as ICro } from 'models/cros/cro'
import { createAsyncAction } from '../../../helpers/common'
import { IRedirect } from '../../../models/common'

export interface ICroEdit {
  cro: ICro
  id: string
}

const getCros = createAsyncAction<IPagedRequest, PagedResult<ICro>, undefined>('[CROS] Get Cros')

const getCroById = createAsyncAction<string, ICro, undefined>('[CROS] Get CRO by ID')

const deleteCro = createAsyncAction<string, ICro, undefined>('[CROS] Delete CRO')

const createCro = createAsyncAction<IRedirect<ICro>, undefined, undefined>('[CROS] Create Cros')

const editCro = createAsyncAction<IRedirect<ICroEdit>, ICro, undefined>('[CROS] Edit CRO')

const clearReducer = createAction<undefined>('[CROS] Clear Reducer')

const setSelectedCro = createAction<ICro | null>('[CROS] Set Selected CRO')

const clearSelectedCro = createAction('[CROS] Clear Selected CRO')

const crosActions = {
  getCros,
  getCroById,
  clearReducer,
  createCro,
  deleteCro,
  setSelectedCro,
  editCro,
  clearSelectedCro
}

export default crosActions
