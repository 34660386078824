import { lazy } from 'react'
import { IRoute } from '../../models/routes'

const userlist = lazy(() => import('./pages/UsersList'))
const createuser = lazy(() => import('./pages/UsersManagement'))

const routes: IRoute[] = [
  {
    component: userlist,
    path: '/users/',
    privateRoute: true,
    exact: true
  },
  {
    component: createuser,
    path: '/users/new',
    privateRoute: true,
    exact: true
  }
]

export default routes
