import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import rootSelectors from 'store/rootselectors'
import { LANGS } from '../helpers/enum'
import translate from '../helpers/translate'
import rootActions from '../store/rootactions'

interface ILocale {
  locale: LANGS
  t: (
    id: string,
    values?: Record<string, string>,
    transform?: 'upper' | 'lower' | 'capital',
    locale?: LANGS
  ) => string
  setLang: (lang: LANGS) => void
}

function useLocale(): ILocale {
  const locale = useSelector(rootSelectors.global.language)
  const d = useDispatch()

  const getNextValue = useCallback(
    (ob: ILocale, ids: string[]): string =>
      ids.length === 1 ? ob[ids[0]] : getNextValue(ob[ids[0]], ids.slice(1)),
    []
  )

  const t = useCallback(
    (id: string, values, transform) => translate(id, values, transform, locale),
    [locale]
  )

  const setLang = useCallback(
    lang => {
      d(rootActions.globalActions.setLanguage.request({ lang }))
    },
    [d]
  )

  return {
    locale,
    t,
    setLang
  }
}

export default useLocale
