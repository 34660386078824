import { ArrowRightOutlined } from '@ant-design/icons'
import { Col, Row, Space, Tag } from 'antd'
import REQUEST_STATUS_COLORS from 'assets/constants/requestStatusColors'
import dayjs from 'dayjs'
import useLocale from 'hooks/useLocale'
import { INotificationPopulated } from 'models/notifications/notification'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import rootActions from 'store/rootactions'
import './styles.less'

interface INotificationItemProps {
  notification: INotificationPopulated
  onIsReadToggle: (id: string, isRead: boolean) => void
}

const NotificationItem: React.FC<INotificationItemProps> = ({
  notification,
  onIsReadToggle
}: INotificationItemProps) => {
  const { t } = useLocale()
  const h = useHistory()
  const d = useDispatch()
  const [isReadIndicator, setIsReadIndicator] = useState(notification.isRead)
  const toggleIndicator = () => {
    if (notification._id) onIsReadToggle(notification._id, !isReadIndicator)
    setIsReadIndicator(!isReadIndicator)
  }

  useEffect(() => {
    setIsReadIndicator(notification.isRead)
  }, [notification.isRead])

  const diffNow = (v: Date): string => {
    const now = dayjs()
    const compareDate = dayjs(v)

    let value: string

    if (now.diff(compareDate, 'minutes') < 60) {
      value = t('dates.labels.minutesAgo', {
        value: now.diff(compareDate, 'minutes').toString()
      })
    } else if (now.diff(compareDate, 'hours') < 24) {
      value = t('dates.labels.hoursAgo', {
        value: now.diff(compareDate, 'hours').toString()
      })
    } else {
      value = t('dates.labels.daysAgo', {
        value: now.diff(compareDate, 'days').toString()
      })
    }

    return value
  }

  return (
    <div className="notification">
      <Row justify="space-between" wrap={false}>
        <Col>
          <Row>
            <p className="notification_main_message">
              <span className="notification_actor">{`${notification.triggerUser?.name} ${notification.triggerUser?.surname}`}</span>
              <span className="notification_message">
                {t(`notifications.msg.${notification?.action}`)}
              </span>
              {notification.createdAt && (
                <span className="notification_date">
                  {diffNow(new Date(notification.createdAt))}
                </span>
              )}
            </p>
          </Row>
          <Row>
            <a
              /* to={`/requests?currentPage=0&pageSize=10&paginate=true&keySearch=${notification.request.requestCode}`} */
              onClick={() => {
                h.push(
                  `/requests?currentPage=0&pageSize=10&paginate=true&keySearch=${notification.request.requestCode}`
                )
                d(rootActions.notificationActions.setVisiblePopOver(false))
              }}
              className="notification_requestCode"
            >
              {`Request - ${notification.request.requestCode}`}
            </a>
          </Row>
          <Row align="middle">
            <Space direction="horizontal">
              <Tag
                className={
                  REQUEST_STATUS_COLORS[notification.requestStatus.from] &&
                  `ant-tag-${REQUEST_STATUS_COLORS[notification.requestStatus.from]}`
                }
              >
                {t(`requests.labels.${(notification.requestStatus.from as string).toLowerCase()}`)}
              </Tag>
              {notification.requestStatus.from !== notification.requestStatus.to && (
                <>
                  <ArrowRightOutlined />
                  <Tag
                    className={
                      REQUEST_STATUS_COLORS[notification.requestStatus.to] &&
                      `ant-tag-${REQUEST_STATUS_COLORS[notification.requestStatus.to]}`
                    }
                  >
                    {t(
                      `requests.labels.${(notification.requestStatus.to as string).toLowerCase()}`
                    )}
                  </Tag>
                </>
              )}
            </Space>
          </Row>
        </Col>
        <Col>
          <button className="custom-button" onClick={toggleIndicator}>
            {!isReadIndicator && <div className="indicator-area"></div>}
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default NotificationItem
