import { PagedResult } from 'models/base/api-response'
import { INotificationPopulated } from 'models/notifications/notification'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IPagedNotificationsRequest } from 'store/notifications/actions'
import rootActions from 'store/rootactions'
import rootSelectors from 'store/rootselectors'

const initialState: IPagedNotificationsRequest = {
  currentPage: 0,
  pageSize: 8,
  onlyUnread: false
}

function useNotifications(): {
  loading: boolean
  notifications: PagedResult<INotificationPopulated>
  totalUnread: number
  refreshNotifications: () => void
} {
  const d = useDispatch()
  const loading = useSelector(rootSelectors.notifications.loading)
  const notifications = useSelector(rootSelectors.notifications.notifications)
  const totalUnread = useSelector(rootSelectors.notifications.totalUnread)

  const [pagedRequest, setPagedRequest] = useState<IPagedNotificationsRequest>({
    currentPage: 0,
    pageSize: 8,
    onlyUnread: false
  })

  const refreshNotifications = () => {
    d(rootActions.notificationActions.clearNotifications())
    setPagedRequest({ ...initialState })
  }

  useEffect(() => {
    d(rootActions.notificationActions.getNotifications.request(pagedRequest))
  }, [pagedRequest])

  return {
    loading,
    notifications,
    totalUnread,
    refreshNotifications
  } as const
}

export default useNotifications
