import { configureStore, EnhancedStore } from '@reduxjs/toolkit'
import labelSagas from 'app/labels/store/sagas'
import createSagaMiddleware from 'redux-saga'
import { REDUCERNAMES } from '../helpers/enum'
import { IAsyncReducer } from '../models/common'
import authSagas from './auth/sagas'
import globalSagas from './global/sagas'
import notificationSagas from './notifications/sagas'
import createRootReducer from './rootreducer'

interface IStore extends EnhancedStore {
  replaceReducers: (...asyncReducers: IAsyncReducer[]) => void
  injectSaga: (key: REDUCERNAMES, saga: () => Generator) => void
}

export function createCustomStore(): IStore {
  const rootReducer = createRootReducer()

  const sagaMiddleware = createSagaMiddleware({
    onError: err => {
      console.error('Unexpected error occurred on saga', err)
    }
  })

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production'
  }) as IStore

  store.replaceReducers = function replaceReducers(...asyncReducers: IAsyncReducer[]) {
    this.replaceReducer(createRootReducer(...asyncReducers))
  }

  function createSagaInjector(runSaga: typeof sagaMiddleware.run) {
    const injectedSagas = new Map()

    const isInjected = (key: string) => injectedSagas.has(key)

    const injectSaga = (key: REDUCERNAMES, saga: () => Generator) => {
      if (isInjected(key)) return

      const task = runSaga(saga)

      injectedSagas.set(key, task)
    }

    injectSaga(REDUCERNAMES.AUTH, authSagas)
    injectSaga(REDUCERNAMES.LABELS, labelSagas.saga)
    injectSaga(REDUCERNAMES.NOTIFICATIONS, notificationSagas.saga)
    injectSaga(REDUCERNAMES.GLOBAL, globalSagas.saga)

    return injectSaga
  }

  store.injectSaga = createSagaInjector(sagaMiddleware.run)

  return store
}

const store = createCustomStore()

export default store
