/* eslint-disable no-plusplus */
import { RcFile } from 'antd/lib/upload'
import { ISetLabelsPrinted, IValidateLabel } from 'app/labels/store/actions'
import { AxiosResponse } from 'axios'
import { IResponseWithPayload, NonPagedResult, PagedResult } from 'models/base/api-response'
import { BusinessUnit, RequestStatus } from 'models/dictionaries'
import {
  IAssignTechnicianPayload,
  IDeleteFileRequestPayload,
  IRequestRejectPayload
} from '../app/request/store/actions'
import { encodeQueryData } from '../helpers/common'
import {
  ICreateRequest,
  IFilesRequest,
  IRequest,
  IRequestPagedRequest,
  IUpdateRequest
} from '../models/requests/request'
import { IUser } from '../models/users/user'
import { instance } from './index.service'

const requestService = {
  assignTechnician: async ({
    id,
    userAssignmentId
  }: IAssignTechnicianPayload): Promise<AxiosResponse<undefined>> =>
    instance.put(`/request/assign/${id}`, { userAssignmentId }),
  getAll: async (request: IRequestPagedRequest): Promise<AxiosResponse<PagedResult<IRequest>>> => {
    const params = encodeQueryData(request)
    return instance.get(`/request?${params}`)
  },
  getById: async (id: string): Promise<AxiosResponse<{ data: IRequest }>> =>
    instance.get(`/request/id/${id}`),
  getByCode: async (code: string): Promise<AxiosResponse<IRequest>> =>
    instance.get(`/request/code/${code}`),
  create: async (request: ICreateRequest): Promise<AxiosResponse<IRequest>> =>
    instance.post(`/request`, request),
  pause: async (request: { [key: string]: any }): Promise<AxiosResponse<IRequest>> =>
    instance.post(`/request/pause`, request),
  reject: async (request: IRequestRejectPayload): Promise<AxiosResponse<IRequest>> =>
    instance.put(`/request/reject/${request.id}`, { reason: request.reason }),
  update: async (
    request: IUpdateRequest | { [key: string]: any },
    status: RequestStatus,
    businessUnit: BusinessUnit,
    id: string
  ): Promise<AxiosResponse<IRequest>> =>
    instance.put(`/request/${id}`, { request, statusToUpdate: status, businessUnit }),
  validateLabels: async (
    verifyObject: IValidateLabel
  ): Promise<AxiosResponse<{ data: IRequest }>> =>
    instance.put(`/request/${verifyObject.requestId}/labels`, {
      labelSize: verifyObject.labelSize
    }),
  setLabelsPrinted: async (
    labelsPrinted: ISetLabelsPrinted
  ): Promise<AxiosResponse<{ data: IRequest }>> =>
    instance.patch(`/request/${labelsPrinted.requestId}/printed`, labelsPrinted.body),
  uploadFile: async (
    file: File,
    type: 'requests' | 'certificates'
  ): Promise<
    AxiosResponse<{
      message: string
      data: {
        url: string
        key: string
      }
    }>
  > => {
    const formData = new FormData()
    formData.append('attachment', file)
    return instance.post(`/request/file/${type}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  uploadFileWithoutRequestCode: async (
    attachments: RcFile[]
  ): Promise<
    AxiosResponse<{
      message: string
      data: IFilesRequest[]
    }>
  > => {
    const formData = new FormData()
    attachments.forEach((element: any) => {
      formData.append('attachment', element.originFileObj)
    })
    return instance.post(`/request/new/file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  uploadFileRequest: async (
    attachments: RcFile[],
    requestCode: string
  ): Promise<AxiosResponse<IResponseWithPayload<IRequest>>> => {
    const formData = new FormData()
    attachments.forEach((element: any) => {
      formData.append('attachment', element.originFileObj)
    })
    return instance.post(`/request/${requestCode}/file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  uploadCompleteFile: async (
    attachment: File,
    requestId: string,
    comment?: string
  ): Promise<AxiosResponse<IResponseWithPayload<IRequest>>> => {
    const formData = new FormData()
    formData.append('attachment', attachment)
    formData.append('requestId', requestId)
    if (comment) formData.append('comment', comment)
    return instance.put(`/request/spf/complete`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  completeRequestNoSPF: async (
    requestId: string
  ): Promise<AxiosResponse<IResponseWithPayload<IRequest>>> =>
    instance.put(`/request/${requestId}/nospf/complete`),
  deleteFileRequest: async ({
    requestCode,
    fileName
  }: IDeleteFileRequestPayload): Promise<AxiosResponse<IRequest>> =>
    instance.delete(`/request/${requestCode}/file/${fileName}`),
  delete: async (id: string): Promise<AxiosResponse<IRequest>> => instance.delete(`/request/${id}`),
  getAllManagers: async (): Promise<AxiosResponse<NonPagedResult<IUser>>> =>
    instance.get('/user/managers'),
  getAllAssignedTechnicians: async (): Promise<AxiosResponse<NonPagedResult<string>>> =>
    instance.get('/user/technicians'),
  getAllActiveRequesters: async (): Promise<AxiosResponse<NonPagedResult<IUser>>> =>
    instance.get('/user/requesters'),
  assignTechnicalDirection: async (id: string): Promise<AxiosResponse<{ data: IRequest }>> =>
    instance.put(`/request/assign/${id}/technicaldirection`)
}

export default requestService
