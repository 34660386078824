import { createAction } from '@reduxjs/toolkit'
import { createAsyncAction } from 'helpers/common'
import { INotificationPopulated } from 'models/notifications/notification'
import { INotificationPopulatedResponse } from 'service/notification.service'

export interface INotificationToggleRequest {
  id: string
}

export interface IPagedNotificationsRequest {
  pageSize: number
  currentPage: number
  onlyUnread: boolean
}

export interface INotificationRequestPayload {
  notification: INotificationPopulated
  isRead: boolean
}

const getNotifications = createAsyncAction<
  IPagedNotificationsRequest,
  INotificationPopulatedResponse,
  undefined
>('[NOTIFICATIONS] Get notifications')

const changeTotalUnread = createAction<boolean>('[NOTIFICATIONS] Change Total')

const setVisiblePopOver = createAction<boolean>('[NOTIFICATIONS] Set visible popover')

const toggleOnlyUnread = createAction('[NOTIFICATIONS] Toggle only unread')

const changeNotificationState = createAsyncAction<
  INotificationRequestPayload,
  INotificationPopulated,
  undefined
>('[NOTIFICATIONS] Change Notification State')

const markAllAsRead = createAsyncAction<undefined, undefined, undefined>(
  '[NOTIFICATIONS] Mark all as read'
)

const clearReducer = createAction<undefined>('[NOTIFICATIONS] Clear Reducer')
const clearNotifications = createAction<undefined>('[NOTIFICATIONS] Clear Notifications')

const notificationActions = {
  getNotifications,
  changeNotificationState,
  changeTotalUnread,
  setVisiblePopOver,
  markAllAsRead,
  clearReducer,
  clearNotifications,
  toggleOnlyUnread
}

export default notificationActions
