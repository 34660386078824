import store from 'store'
import rootActions from 'store/rootactions'
import { KEYS } from '../helpers/enum'
import { instance } from './index.service'
import { IAuthTokens } from '../models/common'

const getTokens = (): IAuthTokens | null => {
  const idToken = localStorage.getItem(KEYS.IDTOKEN)
  const accessToken = localStorage.getItem(KEYS.ACCESSTOKEN)

  return idToken && accessToken ? { accessToken, idToken } : null
}

const removeTokens = (): void =>
  [KEYS.ACCESSTOKEN, KEYS.IDTOKEN].forEach(tokenKey => localStorage.removeItem(tokenKey))

const setTokens = ({ accessToken, idToken }: IAuthTokens): void => {
  removeTokens()

  localStorage.setItem(KEYS.ACCESSTOKEN, accessToken)
  localStorage.setItem(KEYS.IDTOKEN, idToken)

  instance.defaults.headers.Authorization = `Bearer ${idToken}`
}

const logout = (): void => {
  removeTokens()

  store.dispatch(rootActions.authActions.logout())
}

const authService = {
  getTokens,
  setTokens,
  removeTokens,
  logout
}

export default authService
